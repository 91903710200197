import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/Images/App/icon-back.svg";

const headerStyles = {
	backgroundColor: "#e31212",
	padding: "16px",
	textAlign: "center",
	marginBottom: "32px",
	paddingTop: "100px",
};

const DataSecurity = () => {
	const navigate = useNavigate();
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => scrollToTop(), []);

	return (
		<div>
			<div
				style={headerStyles}
				className="relative bg-red-600 p-4 text-center mb-8 pt-24"
			>
				<button
					onClick={() => navigate("/")}
					className="md:block hidden absolute left-4 top-12 md:left-8 md:top-28 bg-white md:py-4 md:px-2 text-red-600 px-4 py-2 rounded-lg"
				>
					Back
				</button>
				<button
					onClick={() => navigate("/")}
					className="md:hidden block absolute left-4 top-24 md:left-8 md:top-28 bg-white md:py-4 md:px-2 text-red-600 px-4 py-2 rounded-lg"
				>
					<img src={BackIcon} alt="Back" />
				</button>
				<h1 className="text-white">Data Security at PDFquery</h1>
			</div>
			<Container>
				<Typography variant="body1">
					<strong>Data Security at PDFquery</strong>
					<br />
					At PDFquery, your privacy and data security are our top priorities. We
					understand the trust you place in us, and we are committed to ensuring
					your information is safe and secure. Here's how we achieve this:
					<br />
				</Typography>
				<br />

				<Typography variant="body1">
					<br />
					<strong>Data Collection</strong>
					<br />
					We only collect the data essential for providing our core services and
					any optional features you choose to use. You always have the option to
					opt out of sharing data specific to these features.
					<br />
				</Typography>
				<br />

				<Typography variant="body1">
					<br />
					<strong>Encryption and Protection</strong>
					<br />
					We employ robust encryption methods to secure your data during
					transmission to our servers and while it is stored on our systems.
					This ensures that your information remains private and protected at
					all times.
					<br />
				</Typography>
				<br />

				<Typography variant="body1">
					<br />
					<strong>Access Controls</strong>
					<br />
					Access to your data is strictly controlled and monitored. Our rigorous
					authentication and authorization processes ensure that only authorized
					personnel can access your information and only for legitimate
					purposes.
					<br />
				</Typography>
				<br />

				<Typography variant="body1">
					<br />
					<strong>Secure Data Processing with OpenAI</strong>
					<br />
					When utilizing OpenAI's API (ChatGPT Enterprise) to process your data,
					your information is not retained for further training or other
					purposes. OpenAI keeps API inputs and outputs for up to 30 days to
					provide their services and detect potential abuse. After this period,
					unless legally required, this data is deleted from their systems. For
					more details, you can refer to OpenAI's enterprise privacy policies.
					<br />
				</Typography>
				<br />

				<Typography variant="body1">
					<br />
					<strong>Compliance and Legal</strong>
					<br />
					We take data protection extremely seriously and our application is
					designed to comply with the strictest data privacy laws and
					regulations, including GDPR and CCPA. Both PDFquery and our partner
					OpenAI are fully certified and compliant with these regulations,
					including OpenAI's SOC2 compliance certification. This ensures that
					your data is handled according to the highest standards of security
					and privacy. Rest assured, we do not share your data with third
					parties for marketing purposes.
					<br />
				</Typography>
				<br />

				<Typography variant="body1">
					<br />
					<strong>Support</strong>
					<br />
					For any concerns, questions, or assistance, our support team is
					readily available at{" "}
					<Link href="mailto:support@pdfquery.ai">support@pdfquery.ai</Link>.
					<br />
				</Typography>
				<br />

				<Typography variant="body1">
					<br />
					At PDFquery, your data security is our responsibility, and we are
					dedicated to maintaining your trust by implementing the most advanced
					security measures. Feel confident that your information is protected
					with us.
				</Typography>
			</Container>
		</div>
	);
};

export default DataSecurity;
