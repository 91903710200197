import React, { useEffect, useState } from "react";
import FileUploadSection from "./UploadPageSection";
import ChatPageSection from "./ChatPageSection";
import { Link } from "react-router-dom";
import BackIcon from "../../Assets/Images/App/icon-back.svg";
import TickIcon from "../../Assets/Images/App/icon-tick.svg";
import config from "../../Utils/config";

export default function PdfFunctionalitySection() {
	const [currentTab, setCurrentTab] = useState("upload");
	const [uploadType, setUploadType] = useState("files");
	const [preLoginId, setPreLoginId] = useState();
	const [noOfFiles, setNoOfFiles] = useState(1);
	const [authFileName, setAuthFileName] = useState(null);
	const [uniqueIdentifier, setUniqueIdentifier] = useState("");
	const [repositoryName, setRepositoryName] = useState("");

	function generateAuth(text, mod = 1000000007) {
		const primes = [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31];
		const primeLen = primes.length;
		const slicedText = text.slice(0, 5);
		let hashValue = 0;
		for (let i = 0; i < slicedText.length; i++) {
			const charValue = slicedText.charCodeAt(i);
			const prime = primes[i % primeLen];
			hashValue = (hashValue + charValue * prime * (i + 1)) % mod;
		}
		setUniqueIdentifier(Number(hashValue));
		return hashValue;
	}

	const handleOauthLogin = () => {
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const url = `${OAUTH_URL}/oauth-v2/check-account?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery`;
		window.location.href = url;
	};

	const features = [];

	return (
		<>
			{/* <div className="my-4 p-2 bg-stone-800 text-center">
				<h6 className=" text-white">
					<Link onClick={handleOauthLogin}>
						<span className="underline">Sign up</span>
					</Link>{" "}
					for FREE to unlock exciting features!! No Card Required!{" "}
				</h6>

			</div> */}

			{currentTab === "upload" ? (
				<FileUploadSection
					setTab={setCurrentTab}
					setPreLoginId={setPreLoginId}
					generateUniqueNumber={generateAuth}
					setAuthFileName={setAuthFileName}
					setUploadType={setUploadType}
					uploadType={uploadType}
					setRepositoryName={setRepositoryName}
					repositoryName={repositoryName}
					setNoOfFiles={setNoOfFiles}
				/>
			) : (
				<ChatPageSection
					setTab={setCurrentTab}
					preLoginId={preLoginId}
					uniqueIdentifier={uniqueIdentifier}
					authFileName={authFileName}
					uploadType={uploadType}
					repositoryName={repositoryName}
					noOfFiles={noOfFiles}
				/>
			)}
		</>
	);
}
