import { Alert, Fade, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";

function getSeverity(status) {
	if (status >= 400 && status <= 599) {
		return "error";
	} else if (status >= 200 && status <= 299) {
		return "success";
	} else if (
		(status >= 100 && status <= 199) ||
		(status >= 300 && status <= 399)
	) {
		return "info";
	}
}

function getTimeOut(status) {
	if (status >= 400 && status <= 599) {
		return 10000;
	} else if (status >= 200 && status <= 299) {
		return 1000;
	} else if (
		(status >= 100 && status <= 199) ||
		(status >= 300 && status <= 399)
	) {
		return 2000;
	} else return 2000;
}

const withSnackbar = (WrappedComponent) => {
	return (props) => {
		const [open, setOpen] = useState(false);
		const [message, setMessage] = useState("");
		const [severity, setSeverity] = useState(null);
		const [pathname, setPathname] = useState(null);
		const [pathstate, setPathstate] = useState(null);
		const [timeOut, setTimeout] = useState(10000);
		const [status, setStatus] = useState(null);
		const [key, setKey] = useState("");

		useEffect(() => {
			if (severity || status) {
				setOpen(true);
			}
		}, [severity, status, key]);

		const setSnackState = ({
			status,
			severity,
			message,
			pathname,
			pathstate,
			timeout = 10000,
		}) => {
			console.log({ status, message, pathname });
			setMessage(message);
			setSeverity(severity);
			setStatus(status);
			setPathname(pathname);
			setPathstate(pathstate);
			setKey(new Date());
			setTimeout(timeout);
		};

		const handleClose = () => {
			setOpen(false);
			// if (pathname) {
			//   if (pathname === "redirect") router.back();
			//   router.push(
			//     {
			//       pathname,
			//       ...(pathstate && { query: pathstate }),
			//     },
			//     pathname
			//   );
			// }
		};

		return (
			<>
				<WrappedComponent {...props} setSnackState={setSnackState} />
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={open}
					autoHideDuration={getTimeOut(status)}
					TransitionComponent={Fade}
					onClose={handleClose}
					// style={{ marginBottom: "644px" }}
				>
					<Alert
						elevation={6}
						variant="filled"
						onClose={handleClose}
						severity={severity || getSeverity(status)}
						style={{
							height: "auto",
							fontSize: 17,
							// lineHeight: '22px',
							// padding: 24,
							whiteSpace: "pre-line",
						}}
					>
						{status === 422
							? message?.map(
									(errorList, index) =>
										`${index + 1}) ${errorList?.loc[1]}: ${errorList.msg} \n`
							  )
							: message === undefined
							? "Something went wrong.."
							: message}
					</Alert>
				</Snackbar>
			</>
		);
	};
};

export default withSnackbar;
