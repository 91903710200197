import {
	Box,
	Button,
	Container,
	Divider,
	Grid,
	Stack,
	TextField,
	Typography,
	InputAdornment,
} from "@mui/material";
import React, { useState } from "react";

import T from "../../Assets/Images/logos/twitter.svg";
import LI from "../../Assets/Images/logos/linkedin.svg";
import YT from "../../Assets/Images/logos/youtube.svg";

import FootLogo from "../../Assets/Images/pdf-query-logo-wh.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../Utils/config";
import withSnackbar from "../../Component/Snackbar";

const FooterSection = ({ setSnackState }) => {
	const currentYear = new Date().getFullYear();
	const previousYear = currentYear - 1;
	const initialState = {
		name: "",
		email: "",
		description: "",
	};
	const subInitialState = {
		name: "",
		email: "",
		description: "Description",
	};

	const [contactUs, setContactUs] = useState(initialState);
	const [subScribe, setSub] = useState(subInitialState);
	const PDF_API_URL = config.PDF_API_URL;

	async function handleSubmit(body) {
		if (!contactUs.name || !contactUs.description || !contactUs.description) {
			setSnackState({
				message: "Please Enter all the fields",
				status: 400,
			});
			return;
		}
		try {
			const response = await axios.post(
				`${PDF_API_URL}/api/support/contact-us/`,
				body
			);
			handleReset();
			setSnackState({
				message: "Submitted Request",
				status: 200,
			});
		} catch (error) {
			setSnackState({
				message: "Error submitting Request",
				status: 400,
			});
		}
	}

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setContactUs((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleReset = () => {
		setContactUs(initialState);
	};

	const handleSubScribe = (e) => {
		setSub((prevState) => ({
			...prevState,
			email: e.target.value,
		}));
	};

	return (
		<>
			<Box id="steps" className="subsection sec_bg_2">
				<Container maxWidth="lg">
					<Box pb={6}>
						<Grid
							container
							alignItems="flex-start"
							justifyContent="space-between"
							spacing={4}
						>
							<Grid item lg={8} lg={6} sm={6} xs={12}>
								<Box className="stepBx">
									<Box pb={2}>
										<Typography variant="h2" className="col1 fw5">
											Let's Talk
										</Typography>
									</Box>

									<Box pb={3}>
										<Typography variant="h5" className="content fw4">
											Increase your productivity 10x. Get accurate <br />
											insights from any documents faster and with ease.
										</Typography>
									</Box>

									<Box className="div_spacing">
										<Divider />
									</Box>

									{/* <Box className="" pt={2}>
                    <Typography variant="h4" className="fw5">
                      FOLLOW US
                    </Typography>
                  </Box> */}

									{/* <Stack direction="row" spacing={2} mt={1} >
                    <a
                      href="https://twitter.com/PDFQuery"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        variant="text"
                        className="socialBtn"
                        startIcon={<img src={T} className="" alt="Twitter" />}
                      >
                        Twitter
                      </Button>
                    </a>

                    <a
                      href="https://www.linkedin.com/company/101656901/admin/feed/posts/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        variant="text"
                        className="socialBtn"
                        startIcon={<img src={LI} className="" alt="Twitter" />}
                      >
                        LinkedIn
                      </Button>
                    </a>

                    <a
                      href="https://www.youtube.com/channel/UCLQIE1CE1oBJXviHUjWYPpA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        variant="text"
                        className="socialBtn"
                        startIcon={<img src={YT} className="" alt="Twitter" />}
                      >
                        Youtube
                      </Button>
                    </a>
                  </Stack> */}
								</Box>
							</Grid>

							<Grid item lg={4} lg={6} sm={6} xs={12}>
								<Box className="contactFrom">
									<Box pb={3}>
										<Typography variant="h2" className="col1 fw5">
											Talk to Us
										</Typography>
									</Box>

									<Box>
										<Grid
											container
											alignItems="top"
											justifyContent="center"
											spacing={2}
										>
											<Grid item lg={12} lg={12} sm={12} xs={12}>
												<Box className="textfield">
													<TextField
														id="filled-basic"
														className="filleddp"
														variant="filled"
														name="name"
														value={contactUs.name}
														label="Full Name"
														fullWidth
														onChange={(e) => handleChangeInput(e)}
													/>
												</Box>
											</Grid>

											<Grid item lg={12} lg={12} sm={12} xs={12}>
												<Box className="textfield">
													<TextField
														id="filled-basic"
														className="filleddp"
														variant="filled"
														name="email"
														label="Email ID"
														value={contactUs.email}
														type="email"
														fullWidth
														onChange={(e) => handleChangeInput(e)}
													/>
												</Box>
											</Grid>

											<Grid item lg={12} lg={12} sm={12} xs={12}>
												<Box className="textfield">
													<TextField
														id="filled-basic"
														className="filleddp"
														variant="filled"
														name="description"
														label="Description"
														fullWidth
														multiline
														value={contactUs.description}
														rows={3}
														onChange={(e) => handleChangeInput(e)}
													/>
												</Box>
											</Grid>

											{/* <Grid item lg={12} lg={12} sm={12} xs={12}>
                        <Box className="uploadfile" mt={0}>
                          <input type="file" class="input-file" />
                        </Box>
                      </Grid> */}
										</Grid>
									</Box>

									{/* <Box mt={2}>
                    <Typography variant="h6" className="content fw4">
                      Optional attachment of up to 20 MB allowed <br />
                      (pdf, docx, png, jpeg)
                    </Typography>
                  </Box> */}

									<Stack direction="row" spacing={2} mt={3}>
										<Button
											variant="outlined"
											className="sbtn2"
											onClick={handleReset}
										>
											RESET
										</Button>
										<Button
											variant="outlined"
											className="sbtn1"
											onClick={() => handleSubmit(contactUs)}
										>
											SUBMIT
										</Button>
									</Stack>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>

			<Box className="footerBg" pt={5} pb={2}>
				<Container maxWidth="lg">
					<Box pb={4}>
						<img
							src={FootLogo}
							className="footer_logo"
							alt="Footer Logo Here"
						/>
					</Box>

					{/* <Box>
            <Typography variant="h4" className="wh">
              Subscribe for latest update
            </Typography>
          </Box>

          <Box>
            <Typography variant="h5" className="wh">
              Subscribe here and be the first to get the latest updates.
            </Typography>
          </Box> */}

					{/* <Box pt={2}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={4}
            >
              <Grid item lg={4} lg={4} sm={6} xs={12}>
                <Box className="textfield foot_txtfield">
                  <TextField
                    size="small"
                    placeholder="Enter Email"
                    variant="outlined"
                    name="email"
                    onChange={handleSubScribe}
                    value={subScribe.email}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            variant="outlined"
                            className="sbtn1"
                            onClick={() => handleSubmit(subScribe)}
                          >
                            Submit
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box> */}

					<Box mt={2} mb={2} className="footerDivider" />

					<Box className="bottom_line_footer">
						<Grid container>
							<Grid item lg={6} lg={6} sm={5} xs={12}>
								<Box mt={1}>
									<Typography className="foot_txt_l">
										<Link to="/privacy" className="link">
											Privacy policy
										</Link>
										{/* &nbsp;|&nbsp; */}
										{/* <Link to="/" className="link">
                      Cookies
                    </Link> */}
										&nbsp;|&nbsp;
										<Link to="/terms" className="link">
											Terms of services
										</Link>
										&nbsp;|&nbsp;
										<Link to="/cookies" className="link">
											Cookies Policy
										</Link>
									</Typography>
								</Box>
							</Grid>
							<Grid item lg={6} lg={6} sm={7} xs={12}>
								<Box mt={1}>
									<Typography className="foot_txt_r">
										Copyright © {previousYear}-{currentYear}.
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default withSnackbar(FooterSection);
