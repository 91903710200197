import { useState } from "react";
import IconRemovePDF from "../../Assets/Images/App/icon-remove.png";

const PopupComponent = ({
	iframeSrc,
	currentDocumentIndex,
	showDocument,
	toggleDocument,
}) => {
	// const [showPopup, setShowPopup] = useState(false);

	// const togglePopup = () => {
	// 	setShowPopup(!showPopup);
	// };

	return (
		<div>
			{showDocument && (
				<div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-10 p-2">
					<div className="lg:hidden w-full lg:w-1/2 mt-32 h-screen flex flex-col relative">
						<div className="lg:hidden h-[90%] border rounded-lg shadow overflow-auto">
							{iframeSrc?.map((item, index) => (
								<iframe
									key={item}
									src={item}
									title="Document"
									className={`w-full h-full border-none ${
										currentDocumentIndex === index ? "block" : "hidden"
									}`}
								></iframe>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PopupComponent;
