import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import { RBtn } from "../../Component/Buttons/RBtn";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FAQData from "../FAQ/FAQData";
import { Link, useNavigate } from "react-router-dom";

export const LandingFAQs = () => {
	const [expanded, setExpanded] = React.useState(false);
	const navigate = useNavigate();

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleClick = (event) => {
		event.preventDefault();
		navigate("/frequently-asked-questions");
	};

	const topFiveQuestions = FAQData.slice(0, 5);

	return (
		<>
			<Box id="faq" className="subsection sec_bg_2 ">
				<Container maxWidth="lg">
					<Box pb={3}>
						<Typography variant="h2" className="black fw4 al_center">
							Frequently <span className="col1">Asked Questions</span> (FAQ)
						</Typography>
					</Box>
					<Box pb={6} mt={4}>
						<Grid
							container
							alignItems="center"
							justifyContent="space-between"
							spacing={2}
						>
							<Grid item lg={12} lg={12} sm={12} xs={12}>
								<Box>
									{topFiveQuestions.map((faq, index) => (
										<Accordion
											key={index}
											expanded={expanded === `panel${index + 1}`}
											onChange={handleChange(`panel${index + 1}`)}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon className="col1" />}
												aria-controls={`panel${index + 1}bh-content`}
												id={`panel${index + 1}bh-header`}
											>
												<Typography variant="h5" className="fw5">
													{faq.question}
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Typography variant="h5" className="content fw4">
													{faq.answer}
												</Typography>
											</AccordionDetails>
										</Accordion>
									))}
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Box pb={3} className="al_center">
						<Link onClick={handleClick}>
							<RBtn btn_name="View more" />
						</Link>
					</Box>
				</Container>
			</Box>
		</>
	);
};
