import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import User1 from "../../Assets/Images/WhoCanUse/academic.jpg";
import User2 from "../../Assets/Images/WhoCanUse/business_c.jpg";
import User3 from "../../Assets/Images/WhoCanUse/financial.jpg";
import User4 from "../../Assets/Images/WhoCanUse/technical_writer.jpg";
import User5 from "../../Assets/Images/WhoCanUse/media.jpg";
import User6 from "../../Assets/Images/WhoCanUse/medical.jpg";
import User7 from "../../Assets/Images/WhoCanUse/legal.jpg";
import User8 from "../../Assets/Images/WhoCanUse/everyone.jpg";
import { RBtn } from "../../Component/Buttons/RBtn";
import config from "../../Utils/config";

export const WhoCanUseIt = () => {
	const handleOauthLogin = () => {
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const url = `${OAUTH_URL}/oauth-v2/create-account-email?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery`;
		window.location.href = url;
	};
	return (
		<>
			<Box id="who-can-use" className="subsection">
				<Container maxWidth="lg">
					<Box pb={3}>
						<Typography variant="h2" className="black fw4 al_center">
							Who Can <span className="col1">Use It?</span>
						</Typography>
					</Box>
					<Box pb={6} mt={4}>
						<Grid
							container
							alignItems="center"
							justifyContent="space-between"
							spacing={2}
						>
							<Grid item lg={3} sm={4} xs={6}>
								<Box className="userBx">
									<Box className="card2 effect-image-1">
										<img src={User1} className="userImg" alt="AI" />
										<div className="overlay simple-overlay"></div>
									</Box>
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Academics and
											<br />
											Researchers
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} sm={4} xs={6}>
								<Box className="userBx">
									<Box className="card2">
										<img src={User2} className="userImg" alt="AI" />
									</Box>
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Business
											<br />
											Consultants
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} sm={4} xs={6}>
								<Box className="userBx">
									<Box className="card2">
										<img src={User3} className="userImg" alt="AI" />
									</Box>
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Financial
											<br />
											Analysts
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} sm={4} xs={6}>
								<Box className="userBx">
									<Box className="card2">
										<img src={User4} className="userImg" alt="AI" />
									</Box>
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Technical Writers
											<br />& Engineers
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} sm={4} xs={6}>
								<Box className="userBx">
									<Box className="card2">
										<img src={User5} className="userImg" alt="AI" />
									</Box>
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Media
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} sm={4} xs={6}>
								<Box className="userBx">
									<Box className="card2">
										<img src={User6} className="userImg" alt="AI" />
									</Box>
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Medical
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} sm={4} xs={6}>
								<Box className="userBx">
									<Box className="card2">
										<img src={User7} className="userImg" alt="AI" />
									</Box>
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Legal
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} sm={4} xs={6}>
								<Box className="userBx">
									<Box className="card2">
										<img src={User8} className="userImg" alt="AI" />
									</Box>
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Everyone
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
					<Box pb={3} className="al_center">
						<RBtn btn_name="Explore for Free" onClick={handleOauthLogin} />
					</Box>
				</Container>
			</Box>
		</>
	);
};
