// app/providers.js
"use client";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import config from "./config";

if (typeof window !== "undefined") {
	posthog.init(config.POSTHOG_KEY, {
		api_host: config.POSTHOG_HOST,
		person_profiles: "always", // or 'always' to create profiles for anonymous users as well
	});
}
export function CSPostHogProvider({ children }) {
	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
