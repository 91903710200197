import { Box, Container } from "@mui/material";
import React from "react";
import { HeroSection } from "./LandingPageSections/HeroSection";
import { VideoSection } from "./LandingPageSections/VideoSection";
import { FeaturesSection } from "./LandingPageSections/FeaturesSection";
import { StepsSection } from "./LandingPageSections/StepsSection";
import { WhoCanUseIt } from "./LandingPageSections/WhoCanUseIt";
import { ComparisonTable } from "./LandingPageSections/ComparisonTable";
import { PricingSection } from "./LandingPageSections/PricingSection";
import FooterSection from "./LandingPageSections/FooterSection";
import PdfFunctionalitySection from "./LandingPageSections/PdfFunctionalitySection";
import { LandingFAQs } from "./LandingPageSections/LandingFAQs";
import { PressReleases } from "./LandingPageSections/PressReleases";
import { UsersTestimonials } from "./LandingPageSections/UsersTestimonials";
import TestimonialSection from "../Component/Intro";
import PointersSection from "../Component/MobileViewSections/M_PointerSections";

export const Home = () => {
	return (
		<>
			{/* <Box className="pageStart">
				<Container maxWidth="xl">
					<HeroSection />
				</Container>
			</Box> */}

			<Box className="mt-16 mb-8">
				<div>
					<PdfFunctionalitySection />
				</div>
			</Box>

			<Box className="lg:hidden">
				<PointersSection />
			</Box>

			<Box className="lg:block hidden">
				<TestimonialSection />
			</Box>

			<Box id="why-pdfquery">
				<VideoSection />
			</Box>

			<Box>
				<FeaturesSection />
			</Box>

			<Box id="how-it-works">
				<StepsSection />
			</Box>

			{/* 11thApril */}
			<Box>
				<UsersTestimonials />
			</Box>
			<Box>
				<PressReleases />
			</Box>
			<Box>
				<LandingFAQs />
			</Box>
			{/* 11thApril */}

			<Box id="who-can-use-it">
				<WhoCanUseIt />
			</Box>

			<Box id="compare-with-chatgpt">
				<ComparisonTable />
			</Box>

			<Box id="pricing">
				<PricingSection />
			</Box>

			<Box id="contact-us">
				<FooterSection />
			</Box>
		</>
	);
};
