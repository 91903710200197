import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "../../Utils/config";
import LoaderButton from "../Buttons/loading-button";
import Image1 from "../../Assets/Images/Testimonials/combined-images.png";
import Safeguard from "../../Assets/Images/Privacy/safeguard.svg";
import IconSafeGuard from "../../Assets/Images/App/icon-safeguard.svg";
import { Tooltip } from "@mui/material";
import IconTooltip from "../../Assets/Images/App/icon-tooltip.svg";

function MobileUploadSection({
	getRootProps,
	getInputProps,
	files,
	removeFile,
	handleRemoveFiles,
	onSubmit,
	loading,
	repositoryName,
	setRepositoryName,
}) {
	const getSlicedFileName = (fileName) => {
		const updatedName =
			fileName.length > 10 ? `${fileName.slice(0, 20)}...` : fileName;
		return updatedName;
	};
	const scrollToSection = (sectionId) => {
		const element = document.getElementById(sectionId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	const [uploadType, setUploadType] = useState("files");
	const navigate = useNavigate();

	// const TestimonialImages = [Image1, Image1, Image1, Image1];

	const handleOauthLogin = () => {
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const url = `${OAUTH_URL}/oauth-v2/create-account-email?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery`;
		window.location.href = url;
	};

	return (
		<div className="">
			<div className="mx-4  bg-white text-center rounded-lg mt-24 mb-4 space-y-3">
				<p className=" font-semibold  mt-8 mb-2 text-[20px] text-wrap font-roboto leading-none tracking-wide">
					Chat With PDFs for free - No Card Needed!
				</p>

				<p className="flex items-center justify-center space-x-2 text-wrap font-roboto font-normal text-green-600 leading-none tracking-wide">
					<img src={Safeguard} alt="" />
					<p>
						We Safeguard Your Privacy.{" "}
						<Link
							target="_blank"
							to={`/security`}
							className="underline cursor-pointer tracking-wide"
						>
							<span className="underline">Learn more</span>
						</Link>
					</p>
				</p>
			</div>
			<div className="flex items-center justify-center h-full mt-8">
				<div className="">
					<div className="flex flex-col bg-white w-[336px] h-[346px] p-4 mx-2 border-2 border-dashed border-red-400 rounded-2xl cursor-pointer hover:border-red-600 transition-colors duration-300">
						<div className="flex flex-col items-center justify-center  my-4">
							<button
								className=" px-16 py-5  mb-2 bg-red-600 font-medium text-white rounded-lg"
								onClick={handleOauthLogin}
							>
								Try for free
							</button>
							<div className="flex flex-col md:flex-row items-center justify-between  space-y-6 md:space-y-0 md:space-x-6">
								<div className="w-full md:max-w-md mx-auto space-y-6 mt-2 md:mb-0">
									<h5 className="text-sm font-medium mb-4 text-[#332A2A] text-left md:text-left">
										Simple Steps To Use PDFquery For Interacting With PDFs
									</h5>
									<div className="flex items-center mb-4">
										<div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-white text-red-600 border border-red-600 rounded-full">
											1
										</div>
										<div className="ml-4">
											<h5 className="flex items-center space-x-1 font-semibold">
												<span>Create</span>
												<span className="font-normal">a collection name</span>
												<Tooltip
													title="A collection is your personal set of PDF documents"
													placement="right"
												>
													<img src={IconTooltip} alt="Tooltip icon" />
												</Tooltip>
											</h5>
										</div>
									</div>
									<div className="flex items-center mb-4">
										<div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-white text-red-600 border border-red-600 rounded-full">
											2
										</div>
										<div className="ml-4">
											<h5 className="font-semibold">
												Upload{" "}
												<span className="font-normal">
													your PDFs in the collection
												</span>
											</h5>
											<h5 className="flex items-center space-x-2 text-green-600 pt-1">
												{/* <img src={IconSafeGuard} alt="" /> */}
												{/* <span className="font-normal font-roboto text-xs">
													We safeguard your privacy.{" "}
													<span
														className="underline"
														onClick={() => navigate("/security")}
													>
														Learn more
													</span>
												</span> */}
											</h5>
										</div>
									</div>
									<div className="flex items-center">
										<div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-white text-red-600 border border-red-600 rounded-full">
											3
										</div>
										<div className="ml-4">
											<h5 className="font-semibold">
												Start conversing{" "}
												<span className="font-normal">with your PDFs!</span>
											</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MobileUploadSection;
