import React from "react";
import Image2 from "../../Assets/Images/Testimonials/combined-images-refined.png";
import Safeguard from "../../Assets/Images/Privacy/safeguard.svg";

function TestimonialSection() {
	return (
		<div className="py-20 bg-gray-100">
			<div className="  sm:text-center mx-2  p-4  text-left lg:text-center rounded-lg font-roboto">
				<div className="space-y-4">
					<h2 className="font-[500] text-center tracking-wide">
						Your Truly Effortless{" "}
						<span className="text-red-600">AI Assistant</span>
					</h2>

					<p className="text-2xl font-normal leading-none text-center mx-2 my-2 ">
						Loved by hundreds of{" "}
						<span className="font-semibold ">
							students, lawyers, researchers{" "}
							<span className="font-normal">and</span> analysts!
						</span>
					</p>
					{/* remember to  */}
					<div className="flex items-center justify-center">
						<img src={Image2} className="" alt="testimonials" />
					</div>
				</div>
			</div>

			{/* <div className="mx-4  bg-white text-center lg:text-center rounded-lg mt-4 mb-4 space-y-3">
				<p className=" font-semibold  mt-8 mb-2 text-[20px] text-wrap font-roboto leading-none tracking-wide">
					Chat With PDFs for free - No Card Needed!
				</p>

				<p className="flex items-center justify-center space-x-2 text-wrap font-roboto font-normal text-green-600 leading-none tracking-wide">
					<img src={Safeguard} alt="" />
					<p>We Safeguard Your Privacy</p>
				</p>
			</div> */}
		</div>
	);
}

export default TestimonialSection;
