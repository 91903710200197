import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

export default function ComparisonTableMUI() {
	return (
		<Box id="mui-table">
			<TableContainer>
				<Table aria-label="caption table">
					<TableHead>
						<TableRow>
							<TableCell className="blackBg wh lbrd sm">Feature</TableCell>
							<TableCell className="gptBg wh lg">ChatGPT</TableCell>
							<TableCell className="bg1 wh rbrd lg">PDFquery.ai</TableCell>
						</TableRow>
					</TableHead>

					<TableBody className="">
						<TableRow className="blankRow">
							<TableCell />
							<TableCell />
							<TableCell />
						</TableRow>

						<TableRow className="oddRow">
							<TableCell className="sm oddCol">
								{" "}
								<Box className="firstColTxt">Primary focus</Box>{" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								General chatbot and text generation {" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Pdf analysis and data extraction {" "}
							</TableCell>
						</TableRow>

						<TableRow className="blankRow">
							<TableCell />
							<TableCell />
							<TableCell />
						</TableRow>

						<TableRow className="evenRow">
							<TableCell className="sm evenCol">
								{" "}
								<Box className="firstColTxt">PDF support </Box>{" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Limited; requires uploading text or converting pdf to text {" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Direct pdf upload and processing {" "}
							</TableCell>
						</TableRow>

						<TableRow className="blankRow">
							<TableCell />
							<TableCell />
							<TableCell />
						</TableRow>

						<TableRow className="oddRow">
							<TableCell className="sm oddCol">
								{" "}
								<Box className="firstColTxt">Search type </Box>{" "}
							</TableCell>
							<TableCell className="lg"> Keyword-based </TableCell>
							<TableCell className="lg">
								{" "}
								Natural language question-based {" "}
							</TableCell>
						</TableRow>

						<TableRow className="blankRow">
							<TableCell />
							<TableCell />
							<TableCell />
						</TableRow>

						<TableRow className="evenRow">
							<TableCell className="sm evenCol">
								{" "}
								<Box className="firstColTxt">Data extraction </Box>{" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Limited to copying and pasting text {" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Automated extraction of tables, figures, and text {" "}
							</TableCell>
						</TableRow>

						<TableRow className="blankRow">
							<TableCell />
							<TableCell />
							<TableCell />
						</TableRow>

						<TableRow className="oddRow">
							<TableCell className="sm oddCol">
								{" "}
								<Box className="firstColTxt">Text analysis </Box>{" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Sentiment analysis and basic topic identification {" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Sentiment analysis, key topic identification, named entity
								recognition, trend analysis {" "}
							</TableCell>
						</TableRow>

						<TableRow className="blankRow">
							<TableCell />
							<TableCell />
							<TableCell />
						</TableRow>

						<TableRow className="evenRow">
							<TableCell className="sm evenCol">
								{" "}
								<Box className="firstColTxt">Document comparison </Box>{" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Manual comparison required {" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Automated comparison for tracking changes and identifying
								differences {" "}
							</TableCell>
						</TableRow>

						<TableRow className="blankRow">
							<TableCell />
							<TableCell />
							<TableCell />
						</TableRow>

						{/* <TableRow className="oddRow">
                            <TableCell className='sm oddCol'> <Box className="firstColTxt">API integration </Box>  </TableCell>
                            <TableCell className='lg'> Limited API access  </TableCell>
                            <TableCell className='lg'> Robust API for seamless integration with workflows  </TableCell>
                        </TableRow>

                        <TableRow className='blankRow'>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                        </TableRow> */}

						{/* <TableRow className="evenRow">
                            <TableCell className='sm evenCol'> <Box className="firstColTxt">Output format </Box>  </TableCell>
                            <TableCell className='lg'> Text </TableCell>
                            <TableCell className='lg'> Text, structured data (tables, CSV), visualizations  </TableCell>
                        </TableRow>

                        <TableRow className='blankRow'>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                        </TableRow> */}

						<TableRow className="oddRow">
							<TableCell className="sm oddCol">
								{" "}
								<Box className="firstColTxt">Suitable for </Box>{" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								General text tasks, casual users {" "}
							</TableCell>
							<TableCell className="lg">
								{" "}
								Researchers, students, businesses working with large volumes of
								PDFs {" "}
							</TableCell>
						</TableRow>

						<TableRow className="blankRow">
							<TableCell />
							<TableCell />
							<TableCell />
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}
