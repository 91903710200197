import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Box,
	Button,
	Container,
	Grid,
	InputAdornment,
	TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import FAQData from "./FAQData";
import FooterSection from "../LandingPageSections/FooterSection";

export default function FAQ() {
	const [expanded, setExpanded] = useState(false);
	const [searchQuery, setSearchQuery] = useState(""); // State to hold the search query
	const [selectedCategory, setSelectedCategory] = useState(""); // State to hold the selected category
	const [filteredFAQData, setFilteredFAQData] = useState(FAQData); // State to hold filtered FAQ data

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => scrollToTop(), []);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleSearchChange = (event) => {
		const { value } = event.target;
		setSearchQuery(value);
		filterFAQData(value, selectedCategory);
	};

	const handleFilterChange = (category) => {
		setSelectedCategory(category);
		filterFAQData(searchQuery, category);
	};

	const filterFAQData = (searchQuery, category) => {
		let filteredData = FAQData;
		if (searchQuery) {
			filteredData = filteredData.filter((faq) =>
				faq.question.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}
		if (category) {
			filteredData = filteredData.filter((faq) => faq.category === category);
		}
		setFilteredFAQData(filteredData);
	};

	return (
		<>
			<Box className="sign-up-strip">
				<Typography variant="h5" className="fw5 wh">
					<Link to="/">
						<u>Sign up</u>
					</Link>{" "}
					to unlock the features!!
				</Typography>
			</Box>
			{/* <Box className="sign-up-strip">
				<Typography variant="h5" className="fw5 wh">
					Our services are under maintenance and will be back up at{" "}
					<strong>22nd April,2024 5PM IST.</strong>{" "}
				</Typography>
			</Box> */}

			<Box id="faq" className="section">
				<Container maxWidth="lg">
					<Box pb={3}>
						<Typography
							variant="h2"
							className="black fw4 al_center"
							gutterBottom
						>
							Frequently <span className="col1">Asked Questions</span> (FAQ)
						</Typography>
						<Typography
							variant="h4"
							className="black fw4 al_center"
							gutterBottom
						>
							We've put together a list of all the questions and answers we
							could think of that you might have
						</Typography>
					</Box>
					<Box pb={6} mt={0}>
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							spacing={2}
						>
							<Grid item lg={6} lg={6} sm={10} xs={12}>
								<Box className="textfield">
									<TextField
										id="filled-basic"
										className="filleddp"
										variant="outlined"
										name="fullname"
										placeholder="Search.."
										fullWidth
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
										value={searchQuery}
										onChange={handleSearchChange}
									/>
								</Box>
							</Grid>

							<Grid item lg={12} lg={12} sm={12} xs={12}>
								<Box className="faq-filters">
									<Box className="filters-stack">
										<Button
											variant="contained"
											className={
												selectedCategory === "" ? "filter active" : "filter"
											}
											onClick={() => handleFilterChange("")}
										>
											All
										</Button>
										{Array.from(
											new Set(FAQData.map((faq) => faq.category))
										).map((category, index) => (
											<Button
												key={index}
												variant="contained"
												className={
													selectedCategory === category
														? "filter active"
														: "filter"
												}
												onClick={() => handleFilterChange(category)}
											>
												{category}
											</Button>
										))}
									</Box>
								</Box>
							</Grid>

							<Grid item lg={12} lg={12} sm={12} xs={12}>
								<Box>
									{filteredFAQData.map((faq, index) => (
										<Accordion
											key={index}
											expanded={expanded === `panel${index + 1}`}
											onChange={handleChange(`panel${index + 1}`)}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon className="col1" />}
												aria-controls={`panel${index + 1}bh-content`}
												id={`panel${index + 1}bh-header`}
											>
												<Typography variant="h5" className="fw5">
													{faq.question}
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Typography variant="h5" className="content fw4">
													{faq.answer}
												</Typography>
											</AccordionDetails>
										</Accordion>
									))}
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>

			<Box id="footer">
				<FooterSection />
			</Box>
		</>
	);
}
