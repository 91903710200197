import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { logClarityEvent } from "../../Utils/clarity";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ForcedRegistrationModal = ({
	isOpen,
	onClose,
	onLogin,
	setEmail,
	email,
}) => {
	const [continueDisabled, setContinueDisabled] = useState(true);

	if (!isOpen) return null;

	const handleChangeEmail = (e) => {
		setEmail(e.target.value);
		emailRegex.test(e.target.value)
			? setContinueDisabled(false)
			: setContinueDisabled(true);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter" && !continueDisabled) {
			onLogin(onClose);
		}
	};

	return (
		<div className="mb-5">
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" open={isOpen} onClose={onClose}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0" />
					</Transition.Child>
					<div className="fixed inset-0 bg-black bg-opacity-60 z-[999] overflow-y-auto">
						<div className="flex items-center justify-center min-h-screen px-4">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel
									as="div"
									className="panel border-0 p-0 rounded-3xl overflow-hidden w-full max-w-lg my-8 text-black dark:text-white-dark bg-white"
								>
									<div className="p-10 text-center ">
										<p className="pb-4 text-black text-start font-roboto text-xl font-semibold leading-6 tracking-wide">
											You're just one step away from querying PDFquery.ai
										</p>
										<p className=" pb-2 text-gray-500 text-start font-roboto text-sm font-normal leading-6 tracking-tight">
											Simply provide your email address to unlock all the
											features and benefits of our product!
										</p>
										<label className="pb-1 flex text-gray-900 font-roboto font-medium text-base justify-start items-start py-2">
											Email Address
										</label>
										<div className="relative py-2">
											<input
												className={`w-full p-2  border border-solid border-gray-300 rounded-lg bg-white focus:outline-none focus:border-red-600 focus:ring-2 focus:ring-red-600 shadow-sm transition-all duration-300 pl-2 pr-10`}
												placeholder="Enter Email Id"
												value={email}
												onChange={handleChangeEmail}
												onKeyDown={handleKeyDown}
											/>
										</div>
										<div className="flex lg:justify-center justify-end items-center mt-8">
											<button
												type="button"
												className="py-4 px-8 lg:px-10 mr-4 font-semibold  text-red-600 border border-red-600 rounded-lg hover:text-white hover:bg-red-600 transition-colors duration-300"
												onClick={onClose}
											>
												Cancel
											</button>
											<button
												type="button"
												className="px-8 lg:px-10 py-2 ml-4 text-sm font-semibold text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors duration-200 ease-in-out disabled:bg-gray-300"
												onClick={() => onLogin(email)}
												disabled={continueDisabled}
											>
												Continue
											</button>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	);
};

export default ForcedRegistrationModal;
