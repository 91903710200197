import * as React from "react";
import { useState } from "react";
import "./Header.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { Menu, Button, Stack } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import MobileDrawer from "./MobileDrawer";
import LOGO from "../../Assets/Images/pdf-query-logo.svg";
import config from "../../Utils/config";
import Cookies from "js-cookie";
import { logClarityEvent } from "../../Utils/clarity";

export default function Header() {
	const [activePage, setActivePage] = useState("");

	const [anchorE2, setAnchorE2] = React.useState(null);

	const handleClick = (event) => {
		setAnchorE2(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorE2(null);
	};

	const scrollToSection = (sectionId) => {
		//remove this.
		const element = document.getElementById(sectionId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	const getURL = () => {
		logClarityEvent("SignInViaHeader", { button_name: "SignInViaHeader" });
		logClarityEvent("SignInViaHeader", "SignInViaHeader");
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const preAccessToken = Cookies.get("_pre_accessToken");
		if (preAccessToken)
			return `${OAUTH_URL}/oauth-v2/check-account?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery&preAccessToken=${preAccessToken}`;
		else
			return `${OAUTH_URL}/oauth-v2/login?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery`;
	};

	const handleOauthLogin = () => {
		const url = getURL();
		window.location.href = url;
	};

	// const scrollToSection = (sectionId) => {
	//   const element = document.getElementById(sectionId);
	//   if (element) {
	//     element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	//     window.scrollBy(0, -50); // Scrolls an additional 50px above the element
	//   }
	// };

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar id="Appbar" position="fixed">
				<Toolbar className="Appbar_height">
					<Box component="div" sx={{ flexGrow: 1 }}>
						<Link
							to="/"
							className={activePage === "home" ? "active" : ""}
							onClick={() => setActivePage("home")}
						>
							{/* <Typography variant='h2' className='txtlogo'> Web<span className='col2'>Template</span> </Typography> */}
							<img
								src={LOGO}
								alt="PDF Query"
								className="md:appbar_logo w-48 "
							/>
						</Link>
					</Box>
					<button
						onClick={handleOauthLogin}
						className="block md:hidden bg-white text-sm text-red-600 px-2 py-2 rounded-md font-medium border border-red-600 text-center leading-none"
					>
						Sign In / Sign up
					</button>

					<Box component="span" className="fx_fe">
						<Box className="desktop_menus">
							<Link
								to="/"
								className={activePage === "Pricing" ? "active" : ""}
								onClick={() => setActivePage("Pricing")}
							>
								<Button
									variant="text"
									className="menus"
									disableRipple
									onClick={() => {
										scrollToSection("pricing");
										handleClose();
									}}
								>
									{" "}
									Pricing{" "}
								</Button>
							</Link>

							<Link
								to="#"
								className={activePage === "Information" ? "active" : ""}
								onClick={() => setActivePage("Information")}
							>
								<Button
									variant="text"
									className="menus"
									disableRipple
									endIcon={<ArrowDropDownIcon />}
									onClick={handleClick}
								>
									{" "}
									Information{" "}
								</Button>
							</Link>

							<Menu
								id="menuId"
								anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
								transformOrigin={{ vertical: "bottom", horizontal: "center" }}
								anchorEl={anchorE2}
								keepMounted
								open={Boolean(anchorE2)}
								onClose={handleClose}
								className="menuitem"
							>
								<Stack direction="column" spacing={2}>
									<Button
										variant="text"
										className="menus dropmenus"
										disableRipple
										onClick={() => {
											scrollToSection("why-pdfquery");
											handleClose();
										}}
									>
										{" "}
										Why PDFquery?{" "}
									</Button>
									<Button
										variant="text"
										className="menus dropmenus"
										disableRipple
										onClick={() => {
											scrollToSection("how-it-works");
											handleClose();
										}}
									>
										{" "}
										How it works?{" "}
									</Button>
									<Button
										variant="text"
										className="menus dropmenus"
										disableRipple
										onClick={() => {
											scrollToSection("who-can-use-it");
											handleClose();
										}}
									>
										{" "}
										Who can use it?{" "}
									</Button>
									<Button
										variant="text"
										className="menus dropmenus"
										disableRipple
										onClick={() => {
											scrollToSection("compare-with-chatgpt");
											handleClose();
										}}
									>
										{" "}
										Compare with ChatGPT{" "}
									</Button>
								</Stack>
							</Menu>

							<Link
								to="/"
								className={activePage === "Contact" ? "active" : ""}
								onClick={() => setActivePage("Contact")}
							>
								<Button
									variant="text"
									className="menus"
									disableRipple
									onClick={() => {
										scrollToSection("contact-us");
										handleClose();
									}}
								>
									{" "}
									Contact Us{" "}
								</Button>
							</Link>

							<Link
								to="/#faq"
								className={activePage === "faq" ? "active" : ""}
								onClick={() => setActivePage("Contact")}
							>
								<Button
									className="menus"
									color="inherit"
									disableRipple
									onClick={() => {
										scrollToSection("faq");
										handleClose();
									}}
								>
									{" "}
									FAQs{" "}
								</Button>
							</Link>

							<Link
								to="/"
								className={activePage === "Sign" ? "active" : ""}
								onClick={() => setActivePage("Sign")}
							>
								<Button
									variant="text"
									className="menus"
									disableRipple
									onClick={handleOauthLogin}
								>
									{" "}
									Sign In{" "}
								</Button>
							</Link>
						</Box>

						<Box className="mobile_menus">
							<MobileDrawer />
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
