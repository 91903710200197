import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import BS from "../../Assets/Images/press-releases/business-standard.png";
import ANI from "../../Assets/Images/press-releases/ani.png";
import TP from "../../Assets/Images/press-releases/the_print.png";
import RN from "../../Assets/Images/press-releases/republic-today.png";
import HT from "../../Assets/Images/press-releases/ht-tech.png";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 5,
		slidesToSlide: 1, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 3,
		slidesToSlide: 1, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 2,
		slidesToSlide: 1, // optional, default to 1.
	},
};

export const PressReleases = () => {
	return (
		<>
			<Box id="press-releases" className="subsection">
				<Container maxWidth="lg">
					<Box pb={3}>
						<Typography variant="h2" className="black fw4 al_center">
							Official <span className="col1">Press Release</span> Announcement
						</Typography>
					</Box>
					<Box pb={6} mt={4}>
						<Grid
							container
							alignItems="center"
							justifyContent="space-between"
							spacing={2}
						>
							<Grid item lg={12} lg={12} sm={12} xs={12}>
								<Carousel
									swipeable={true}
									draggable={true}
									showDots={false}
									arrows={false}
									responsive={responsive}
									infinite={true}
									autoPlay={true}
									pauseOnHover={true}
									autoPlaySpeed={2500}
									keyBoardControl={true}
									customTransition="all .5 ease-in"
									transitionDuration={2500}
									containerClass="carousel-container"
									dotListClass="custom-dot-list-style"
									itemClass="carousel-item-padding-40-px"
								>
									<a
										href="https://www.business-standard.com/content/press-releases-ani/smartcloud-launches-pdfquery-transforming-pdf-document-interaction-with-ai-124030400626_1.html"
										target="_blank"
										rel="noreferrer noopener"
									>
										<Box className="press_releases">
											<img draggable={false} src={BS} alt="Business Standard" />
										</Box>
									</a>

									<a
										href="https://www.aninews.in/news/business/smartcloud-launches-pdfquery-transforming-pdf-document-interaction-with-ai20240304150340/"
										target="_blank"
										rel="noreferrer noopener"
									>
										<Box className="press_releases">
											<img
												draggable={false}
												src={ANI}
												alt="Business Standard"
											/>
										</Box>
									</a>

									<a
										href="https://theprint.in/ani-press-releases/smartcloud-launches-pdfquery-transforming-pdf-document-interaction-with-ai/1987670/"
										target="_blank"
										rel="noreferrer noopener"
									>
										<Box className="press_releases">
											<img draggable={false} src={TP} alt="Business Standard" />
										</Box>
									</a>

									<a
										href="https://republicnewstoday.com/index.php/2024/03/05/smartcloud-launches-pdfquery-transforming-pdf-document-interaction-with-ai/"
										target="_blank"
										rel="noreferrer noopener"
									>
										<Box className="press_releases">
											<img draggable={false} src={RN} alt="Business Standard" />
										</Box>
									</a>

									<a
										href="https://tech.hindustantimes.com/tech/news/5-things-about-ai-you-may-have-missed-today-nothing-eyes-ai-boost-fake-ai-images-in-polls-more-71709561132012.html"
										target="_blank"
										rel="noreferrer noopener"
									>
										<Box className="press_releases">
											<img draggable={false} src={HT} alt="Business Standard" />
										</Box>
									</a>
								</Carousel>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
};
