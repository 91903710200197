import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/Images/App/icon-back.svg";

const headerStyles = {
	backgroundColor: "#e31212",
	padding: "16px",
	textAlign: "center",
	marginBottom: "32px",
	paddingTop: "100px",
};

const PrivacyPolicy = () => {
	const navigate = useNavigate();
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => scrollToTop(), []);

	return (
		<div>
			<div
				style={headerStyles}
				className="relative bg-red-600 p-4 text-center mb-8 pt-24"
			>
				<button
					onClick={() => navigate("/")}
					className="md:block hidden absolute left-4 top-12 md:left-8 md:top-28 bg-white md:py-4 md:px-2 text-red-600 px-4 py-2 rounded-lg"
				>
					Back
				</button>
				<button
					onClick={() => navigate("/")}
					className="md:hidden block absolute left-4 top-24 md:left-8 md:top-28 bg-white md:py-4 md:px-2 text-red-600 px-4 py-2 rounded-lg"
				>
					<img src={BackIcon} alt="" />
				</button>
				<h1 className="text-white">Privacy Policy</h1>
			</div>
			<Container>
				<Typography variant="body1">
					<strong>Introduction</strong>
					<br />
					Welcome to PDFquery! We prioritize your privacy and are dedicated to
					protecting it. This Privacy Policy provides details on the information
					we collect, its usage, the security measures in place, and your rights
					regarding your personal data. By using our web application, you agree
					to the terms outlined in this policy.
					<br />
				</Typography>
				<br />

				<Typography variant="body1">
					<br />
					<strong>Data Collection</strong>
					<br />
					To deliver our services and enhance your experience, we collect the
					following information:
					<ul>
						<li>
							Email Addresses and Phone Numbers: For account setup, management,
							and communication.
						</li>
						<li>Passwords: To ensure secure logins.</li>
						<li>
							Payment Details: Our payment integration partner, in compliance
							with RBI regulations, processes transactions and provides billing
							transparency. This includes collecting card details necessary for
							payment processing.
						</li>
						<br />
					</ul>
				</Typography>

				<Typography variant="body1">
					<strong>Data Usage</strong>
					<br />
					The information collected is used to:
					<ul>
						<li>Improve service quality and user experience.</li>
						<li>Offer personalized experiences and for marketing purposes.</li>
						<li>
							Conduct analysis on usage and user behavior through Google
							Analytics and Microsoft Clarity.
						</li>
						<li>
							Generate chat responses with ChatGPT, aiming to enhance service
							performance.
						</li>
					</ul>
					We commit to not share documents or personal information with any
					third parties, aside from necessary vendors like Fastspring for
					payment processing, Google Analytics, and Microsoft Clarity for
					analysis, and OpenAI for conversation generation.
				</Typography>

				<Typography variant="body1">
					<br />
					<strong>Data Sharing</strong>
					<br />
					We do not share your data with any third parties beyond those
					essential for providing our services.
				</Typography>

				<Typography variant="body1">
					<br />
					<strong>Data Security</strong>
					<br />
					Your data's security is paramount. We employ encryption and robust
					security measures during data transmission and storage. Our security
					practices are designed to protect your information against
					unauthorized access, alteration, and misuse.
				</Typography>

				<Typography variant="body1">
					<br />
					<strong>User Rights</strong>
					<br />
					You have comprehensive rights over your data, including the ability
					to:
					<ul>
						<li>
							Access, edit, or delete your personal data, including
							conversations, documents, and account information.
						</li>
						<li>
							Request deletion of associated payment details from our system,
							which will be actioned within 7 days of account deletion.
						</li>
					</ul>
				</Typography>

				<Typography variant="body1">
					<br />
					<strong>International Data Transfer</strong>
					<br />
					We adhere to stringent data security and encryption policies,
					consistent with global data protection regulations, to safeguard your
					data across international borders.
				</Typography>

				<Typography variant="body1">
					<br />
					<strong>Children's Privacy</strong>
					<br />
					Our service is accessible to users of all ages. However, we do not
					intentionally collect data from children under the age of 13, ensuring
					the protection of minors.
				</Typography>

				<Typography variant="body1">
					<br />
					<strong>Compliance and Legal</strong>
					<br />
					Our operations are designed to comply with prevalent data protection
					laws, including GDPR and CCPA. This commitment extends to ensuring
					that user content is not shared with third parties for marketing
					purposes and that data security measures meet the highest standards.
				</Typography>

				<Typography variant="body1">
					<br />
					<strong>Policy Updates</strong>
					<br />
					We reserve the right to update this privacy policy as necessary.
					Changes will be communicated through our website, and we encourage
					users to review the policy periodically to stay informed.
				</Typography>

				<Typography variant="body1">
					<br />
					<strong>Contact Information</strong>
					<br />
					For any inquiries or concerns about our privacy practices, please
					contact us at{" "}
					<Link href="mailto:support@pdfquery.ai">support@pdfquery.ai</Link>.
				</Typography>
			</Container>
		</div>
	);
};

export default PrivacyPolicy;
