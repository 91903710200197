import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const UsersTestimonials = () => {
	const responsiveOptions = {
		0: {
			items: 1,
		},
		600: {
			items: 2,
		},
		1000: {
			items: 3,
		},
	};

	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const [open2, setOpen2] = React.useState(false);
	const handleClickOpen2 = () => {
		setOpen2(true);
	};
	const handleClose2 = () => {
		setOpen2(false);
	};

	const [open3, setOpen3] = React.useState(false);
	const handleClickOpen3 = () => {
		setOpen3(true);
	};
	const handleClose3 = () => {
		setOpen3(false);
	};

	const [open4, setOpen4] = React.useState(false);
	const handleClickOpen4 = () => {
		setOpen4(true);
	};
	const handleClose4 = () => {
		setOpen4(false);
	};

	return (
		<>
			<Box id="users-love" className="subsection">
				<Container maxWidth="lg">
					<Box>
						<p className="font-semibold text-center text-2xl leading-none">
							Discover Why Our Users Love Us
						</p>
					</Box>
					<Box pb={6} mt={4}>
						<Grid
							container
							alignItems="center"
							justifyContent="space-between"
							spacing={2}
						>
							<OwlCarousel
								className="owl-theme reportinfo"
								loop
								margin={10}
								nav={true}
								dots={false}
								autoplayHoverPause={true}
								autoplay
								responsive={responsiveOptions}
							>
								<Box className="testimonial_bx">
									<Box>
										<Typography variant="h5" className="bl fw5">
											Santosh Pandit
										</Typography>
										<Typography variant="h5" className="h5v2 fw5 content">
											Cybersecurity expert
										</Typography>
									</Box>
									<Box pt={2} className="">
										<Typography variant="h5" className="h5v2 fw4 bl ellip_desc">
											This tool <strong>amazed me</strong> with its ability to{" "}
											<strong>extract specific information</strong> from the
											PDF, even if it's scattered across those 128 pages. What's
											more, it presents the results in a{" "}
											<strong>user-friendly format</strong>,
											<strong>without adding any unnecessary fluff</strong> like
											some other tools tend to do.
										</Typography>
									</Box>
									<Box pt={1} className="al_right" onClick={handleClickOpen}>
										<Button variant="text" className="read-more">
											Read more
										</Button>
									</Box>
								</Box>

								<Box className="testimonial_bx">
									<Box>
										<Typography variant="h5" className="bl fw5">
											Sandeep Gupta
										</Typography>
										<Typography variant="h5" className="h5v2 fw5 content">
											MD, Shyam Indofab
										</Typography>
									</Box>
									<Box pt={2} className="">
										<Typography variant="h5" className="h5v2 fw4 bl ellip_desc">
											"As an entrepreneur, PDFquery has{" "}
											<strong>transformed the way I manage information</strong>,
											offering a seamless interface for{" "}
											<strong>engaging with and extracting insights</strong>{" "}
											from PDF documents. Its{" "}
											<strong>efficiency and precision</strong> save significant
											time and enhance productivity, making it essential for
											gaining a <strong>competitive edge</strong>{" "}
										</Typography>
									</Box>
									<Box pt={1} className="al_right" onClick={handleClickOpen2}>
										<Button variant="text" className="read-more">
											Read more
										</Button>
									</Box>
								</Box>

								<Box className="testimonial_bx">
									<Box>
										<Typography variant="h5" className="bl fw5">
											Anant Sood
										</Typography>
										<Typography variant="h5" className="h5v2 fw5 content">
											Co-founder worxogo
										</Typography>
									</Box>
									<Box pt={2} className="">
										<Typography variant="h5" className="h5v2 fw4 bl ellip_desc">
											As the founder of worxogo, PDFquery has been{" "}
											<strong>exceptional in streamlining</strong> our access to
											vast quantities of <strong>research and training</strong>{" "}
											materials,{" "}
											<strong>drastically improving the efficiency</strong> of
											building our <strong>high-performing sales</strong> and{" "}
											<strong>customer service </strong>teams. Its{" "}
											<strong>precise data extraction </strong>
											and <strong>quick insights</strong> have been crucial in
											in personalizing nudges and driving better work behaviors,
											making PDFquery{" "}
											<strong>an indispensable tool for accelerating</strong>{" "}
											learning and enhancing <strong>team productivity</strong>
										</Typography>
									</Box>
									<Box pt={1} className="al_right" onClick={handleClickOpen3}>
										<Button variant="text" className="read-more">
											Read more
										</Button>
									</Box>
								</Box>

								{/* <Box className="testimonial_bx">
									<Box>
										<Typography variant="h5" className="bl fw5">
											Piyush Sharma
										</Typography>
										<Typography variant="h5" className="h5v2 fw5 content">
											Distinguished Professor
										</Typography>
									</Box>
									<Box pt={2} className="">
										<Typography variant="h5" className="h5v2 fw4 bl ellip_desc">
											<strong>
												This save significant time but also act as a powerful
												accelerator for learning and gaining knowledge,
												providing on-the-go insights that enhance my academic
												productivity. This tool is indispensable for anyone
												aiming to streamline their research and quickly access
												accurate information.
											</strong>{" "}
										</Typography>
									</Box>
									<Box pt={1} className="al_right" onClick={handleClickOpen4}>
										<Button variant="text" className="read-more">
											Read more
										</Button>
									</Box>
								</Box> */}
							</OwlCarousel>
						</Grid>
					</Box>
				</Container>
			</Box>

			{/* Testimonial 1 dialog */}
			<Dialog
				fullWidth={true}
				maxWidth={"xs"}
				open={open}
				onClose={handleClose}
			>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: "#000",
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<DialogContentText>
						<Box>
							<Grid
								container
								rowSpacing={2}
								columnSpacing={2}
								alignItems="top"
								justifyContent="flex-start"
							>
								<Grid item lg={12} lg={12} sm={12} xs={12}>
									<Box>
										<Typography variant="h5" className="bl fw5">
											Santosh Pandit
										</Typography>
										<Typography variant="h5" className="h5v2 fw5 content">
											Cybersecurity expert
										</Typography>
									</Box>
									<Box pt={2} className="">
										<Typography variant="h5" className="h5v2 fw4 bl ellip_desc">
											This tool <strong>amazed me</strong> with its ability to{" "}
											<strong>extract specific information</strong> from the
											PDF, even if it's scattered across those 128 pages. What's
											more, it presents the results in a{" "}
											<strong>user-friendly format</strong>,
											<strong>without adding any unnecessary fluff</strong> like
											some other tools tend to do.
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</DialogContentText>
				</DialogContent>
			</Dialog>
			{/* Testimonial 1 dialog */}

			{/* Testimonial 2 dialog */}
			<Dialog
				fullWidth={true}
				maxWidth={"xs"}
				open={open2}
				onClose={handleClose2}
			>
				<IconButton
					aria-label="close"
					onClick={handleClose2}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: "#000",
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<DialogContentText>
						<Box>
							<Grid
								container
								rowSpacing={2}
								columnSpacing={2}
								alignItems="top"
								justifyContent="flex-start"
							>
								<Grid item lg={12} lg={12} sm={12} xs={12}>
									<Box>
										<Typography variant="h5" className="bl fw5">
											Sandeep Gupta
										</Typography>
										<Typography variant="h5" className="h5v2 fw5 content">
											MD, Shyam Indofab
										</Typography>
									</Box>
									<Box pt={2} className="">
										<Typography variant="h5" className="h5v2 fw4 bl ellip_desc">
											"As an entrepreneur, PDFquery has{" "}
											<strong>transformed the way I manage information</strong>,
											offering a seamless interface for{" "}
											<strong>engaging with and extracting insights</strong>{" "}
											from PDF documents. Its{" "}
											<strong>efficiency and precision</strong> save significant
											time and enhance productivity, making it essential for
											gaining a <strong>competitive edge</strong>{" "}
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</DialogContentText>
				</DialogContent>
			</Dialog>
			{/* Testimonial 2 dialog */}

			{/* Testimonial 3 dialog */}
			<Dialog
				fullWidth={true}
				maxWidth={"xs"}
				open={open3}
				onClose={handleClose3}
			>
				<IconButton
					aria-label="close"
					onClick={handleClose3}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: "#000",
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<DialogContentText>
						<Box>
							<Grid
								container
								rowSpacing={2}
								columnSpacing={2}
								alignItems="top"
								justifyContent="flex-start"
							>
								<Grid item lg={12} lg={12} sm={12} xs={12}>
									<Box>
										<Typography variant="h5" className="bl fw5">
											Anant Sood
										</Typography>
										<Typography variant="h5" className="h5v2 fw5 content">
											Co-founder worxogo
										</Typography>
									</Box>
									<Box pt={2} className="">
										<Typography variant="h5" className="h5v2 fw4 bl ">
											As the founder of worxogo, PDFquery has been{" "}
											<strong>exceptional in streamlining</strong> our access to
											vast quantities of <strong>research and training</strong>{" "}
											materials,{" "}
											<strong>drastically improving the efficiency</strong> of
											building our <strong>high-performing sales</strong> and{" "}
											<strong>customer service </strong>
											teams. Its <strong>
												precise data extraction
											</strong> and <strong>quick insights</strong> have been
											crucial in in personalizing nudges and driving better work
											behaviors, making PDFquery{" "}
											<strong>an indispensable tool for accelerating</strong>{" "}
											learning and enhancing <strong>team productivity</strong>
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</DialogContentText>
				</DialogContent>
			</Dialog>
			{/* Testimonial 3 dialog */}

			{/* Testimonial 4 dialog */}
			{/* <Dialog
				fullWidth={true}
				maxWidth={"xs"}
				open={open4}
				onClose={handleClose4}
			>
				<IconButton
					aria-label="close"
					onClick={handleClose4}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: "#000",
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<DialogContentText>
						<Box>
							<Grid
								container
								rowSpacing={2}
								columnSpacing={2}
								alignItems="top"
								justifyContent="flex-start"
							>
								<Grid item lg={12} lg={12} sm={12} xs={12}>
									<Box>
										<Typography variant="h5" className="bl fw5">
											Piyush Sharma
										</Typography>
										<Typography variant="h5" className="h5v2 fw5 content">
											Distinguished Professor
										</Typography>
									</Box>
									<Box pt={2} className="">
										<Typography variant="h5" className="h5v2 fw4 bl ellip_desc">
											<strong>
												This save significant time but also act as a powerful
												accelerator for learning and gaining knowledge,
												providing on-the-go insights that enhance my academic
												productivity. This tool is indispensable for anyone
												aiming to streamline their research and quickly access
												accurate information.
											</strong>{" "}
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</DialogContentText>
				</DialogContent>
			</Dialog> */}
			{/* Testimonial 4 dialog */}
		</>
	);
};
