import React, { useEffect, useRef } from "react";
import Link from "@mui/material/Link";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../Assets/Images/App/icon-back.svg";

const CookiePolicy = () => {
	const scriptRef = useRef();
	const navigate = useNavigate();

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => scrollToTop(), []);

	useEffect(() => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.charset = "UTF-8";
		script.setAttribute("data-cookiescriptreport", "report");
		script.src =
			"//report.cookie-script.com/r/ef8bb05486452d1fd3005d54b2a98912.js";

		document.body.appendChild(script);
		scriptRef.current.appendChild(script);
	}, []);

	return (
		<div>
			<div className="relative bg-red-600 p-4 text-center mb-8 pt-24">
				<button
					onClick={() => navigate("/")}
					className="md:block hidden absolute left-4 top-12 md:left-8 md:top-28 bg-white md:py-4 md:px-2 text-red-600 px-4 py-2 rounded-lg"
				>
					Back
				</button>
				<button
					onClick={() => navigate("/")}
					className="md:hidden block absolute left-4 top-24 md:left-8 md:top-28 bg-white md:py-4 md:px-2 text-red-600 px-4 py-2 rounded-lg"
				>
					<img src={BackIcon} alt="" />
				</button>
				<h1 className="text-white">Cookie Policy for PDFquery</h1>
			</div>

			<Container>
				<h5>
					Our website, https://pdfquery.ai/ , uses cookies to improve your
					experience and provide services and features that are tailored to your
					interests. This policy provides detailed information about how and why
					we use cookies and how you can manage them.
				</h5>
				<h5 className="py-4">
					<strong>What Are Cookies? </strong>
					<br />
					Welcome to PDFquery! We prioritize your privacy and are dedicated to
					protecting it. This Privacy Policy provides details on the information
					we collect, its usage, the security measures in place, and your rights
					regarding your personal data. By using our web application, you agree
					to the terms outlined in this policy.
				</h5>

				<h5>
					<strong>How We Use Cookies </strong>
					We use the following types of cookies on our website:
					<br />
					<ul className="list">
						<li className=" mx-8 py-1 list-disc">
							<span className="font-bold">Strictly Necessary Cookies:</span>{" "}
							These are essential for you to browse our website and use its
							features. Without them, services like shopping carts cannot be
							provided.{" "}
						</li>
						<li className=" mx-8 py-1 list-disc">
							<span className="font-bold">Performance Cookies:</span> These
							cookies collect information about how you use our website, like
							which pages you visited and which links you clicked on. None of
							this information can be used to identify you. It is all aggregated
							and, therefore, anonymized.{" "}
						</li>
						<li className=" mx-8 py-1 list-disc">
							<span className="font-bold">Functional Cookies:</span> These
							cookies allow our website to remember choices you have made in the
							past, like what language you prefer or what your username and
							password are so you can automatically log in.{" "}
						</li>
						<li className=" mx-8 py-1 list-disc">
							<span className="font-bold">Targeting Cookies:</span> These
							cookies track your browsing habits so we can show you advertising
							that is more likely to be of interest to you. These cookies use
							information about your browsing history to group you with other
							users who have similar interests.{" "}
						</li>
					</ul>
				</h5>

				<h5 className="py-2">
					<strong>Third-Party Cookies</strong> We also use third-party cookies
					to provide tracking and advertising services. These include:
					<ul className="list">
						<li className=" mx-8 py-1 list-disc">
							Cookies from Microsoft Clarity, which help us understand user
							behavior on our site to improve our services.
						</li>
						<li className=" mx-8 py-1 list-disc">
							Cookies from Google AdSense, which are used to display relevant
							advertisements to our users.
						</li>
					</ul>
				</h5>
				<h5 className="py-2">
					<strong>Cookie List</strong> Here is a detailed list of cookies we
					use, along with their purpose and lifespan:
				</h5>
				<div id="cookies-table">
					<div ref={scriptRef} />
				</div>

				<br />
				<h5 className="py-2">
					<strong>Changes to the Cookie Policy</strong> We may update this
					policy from time to time, particularly as technology changes. You can
					always check this page for the latest version.
				</h5>
				<h5 className="py-2">
					<strong>Contact Us</strong> If you have any questions about this
					cookie policy or the information we hold about you, please contact us
					at <Link href="mailto:support@pdfquery.ai">support@pdfquery.ai</Link>.
				</h5>
			</Container>
		</div>
	);
};

export default CookiePolicy;
