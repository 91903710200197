import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";

const RemoveFilesModal = ({ isOpen, onClose, onConfirmRemove }) => {
	if (!isOpen) return null;
	return (
		<div className="mb-5">
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" open={isOpen} onClose={onClose}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0" />
					</Transition.Child>
					<div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
						<div className="flex items-center justify-center min-h-screen px-4">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel
									as="div"
									className="panel bg-white border-0 p-0 rounded-3xl overflow-hidden w-full max-w-lg my-8 text-black dark:text-white-dark"
								>
									<div className="p-10 text-center ">
										<p className="pb-4 text-black text-center font-roboto text-xl font-semibold leading-6 tracking-wide">
											Are you sure?
										</p>
										<p className="text-gray-900 text-center font-roboto text-base font-normal leading-6 tracking-tight">
											Removing all uploaded files will restart the process. Are
											you sure you want to proceed?
										</p>
										<div className="flex justify-center items-center mt-8">
											<button
												type="button"
												className="btn btn-outline-danger py-2 px-12"
												onClick={onConfirmRemove}
											>
												Yes
											</button>
											<button
												type="button"
												className="py-2 px-12 ml-4 text-sm text-white bg-red-600 rounded hover:bg-red-700 transition-colors duration-200 ease-in-out disabled:bg-gray-300"
												onClick={onClose}
											>
												No
											</button>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	);
};

export default RemoveFilesModal;
