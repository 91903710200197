import { Link } from "react-router-dom";

const FAQData = [
	{
		category: "General Information",
		question: "What is PDFquery?",
		answer:
			"PDFquery is an AI tool for interacting with PDFs, allowing users to have natural conversations with their PDF documents for gaining insights and knowledge efficiently.",
	},
	{
		category: "General Information",
		question: "Who is PDFquery designed for?",
		answer:
			"It's designed for a diverse user base, including academics, researchers, business consultants, legal professionals, and more. Some use cases include employee onboarding and upskilling, industrial maintenance and repair, medical history management.",
	},
	{
		category: "General Information",
		question: "What makes PDFquery different from other PDF tools?",
		answer:
			"PDFquery provides AI powered accurate answers to natural language queries with accuracy within the provided context. Complete truth, long contextual history, AI based search rather than keyword based.",
	},
	{
		category: "Getting Started",
		question: "How do I start using PDFquery?",
		answer:
			"You can start using PDFquery for signing up for free. You need access to an active email and do not require any card to get started.",
	},
	{
		category: "Getting Started",
		question: "Is there a tutorial or guide for new users?",
		answer: (
			<>
				While PDFquery offers a simplistic and intuitive UI for anyone to get
				started immediately, you can find more information about PDFquery{" "}
				<Link to="https://youtu.be/91mtDiCmlgw?feature=shared" target="_blank">
					<span className="text-red-600">Here</span>
				</Link>
			</>
		),
	},
	{
		category: "Getting Started",
		question: "Can I try PDFquery for free?",
		answer: "Yes, PDFquery offers a free plan for new users.",
	},
	{
		category: "Features and Functionality",
		question: "What key features does PDFquery offer?",
		answer:
			"Key features include AI-powered search, support for multiple file formats, data extraction in seconds, document comparison, long conversations, and provision of accurate, contextual answers.",
	},
	{
		category: "Features and Functionality",
		question: "Can PDFquery extract data from tables and images?",
		answer:
			"While currently, data extraction is not the prime focus of PDFquery, it’ll soon have extraction capabilities to follow custom extraction requests.",
	},
	{
		category: "Features and Functionality",
		question: "What subscription plans are available?",
		answer:
			"PDFquery has plans suitable for all kinds of users. For students and for people who just want to try out PDFquery, free plan is available. For larger, consistent use, Pro plan can be opted. For vast usage, Pro Plus plan can be opted by professionals and teams.",
	},
	{
		category: "Features and Functionality",
		question: "Which file formats does PDFquery support?",
		answer:
			"PDFquery supports PDFs and is planning to extend support to more file types including CSV and databases.",
	},
	{
		category: "Features and Functionality",
		question: "How can I search for specific information within a PDF?",
		answer:
			"To search specific information within PDFs, you can pose a question in natural language using exact or near similar terms for the information you’re seeking. PDFquery will effectively provide you with rephrased or exact citations as per your request regarding the specific information you seek. Alternatively, you can ask PDFquery to tell you the page number of the document where the topic is covered in detail. PDFquery will provide you the answer and you can scroll through the document inside PDFquery to locate it right away.",
	},
	{
		category: "Use Cases",
		question: "How does PDFquery's AI-powered search work?",
		answer:
			"Unlike typical keyword search which only highlights the exact word in the document, AI powered search provides you detailed answer to your questions by understanding the semantics and context of your questions. Also, rather than just highlighting the keyword, PDFquery provides concise answer to specific question by extracting and processing the specific information in the frame of the context thus reducing the time and effort to find accurate answers to your questions.",
	},
	{
		category: "Use Cases",
		question: "How does PDFquery facilitate document comparison?",
		answer:
			"PDFquery allows you to easily compare documents to track changes, identify differences, and analyze trends over time.",
	},
	{
		category: "Use Cases",
		question: "What does PDFquery mean by 'long conversations'?",
		answer:
			"PDFquery retains context for a long time, making it easy to maintain the conversational flow.",
	},
	{
		category: "Use Cases",
		question: "What is meant by 'Only Truth' in PDFquery's features?",
		answer:
			"PDFquery aims to provide no hallucination or false answers, only accurate, contextual information. In case answer of any question falls outside of the context of the documents provided, PDFquery truthfully answers indicating that the answer is not covered in the context rather than providing false information.",
	},
	{
		category: "Use Cases",
		question: "How does the AI-powered conversation with PDFs work??",
		answer:
			"AI-powered conversations with PDFs let you talk to a computer program about what's inside a PDF, just like you'd ask a friend to explain a book they read. The program reads and understands the PDF, then answers your questions or discusses the content with you, making it easier to get the info you need without having to read everything yourself.",
	},
	{
		category: "Use Cases",
		question: "Does PDFquery support document comparison?",
		answer:
			"PDFquery allows you to upload and ask questions to multiple documents at a time. Soon you will be able to easily compare documents to track changes, identify differences, and analyze trends over time.",
	},
	{
		category: "Use Cases",
		question: "How can I search for specific information within a PDF?",
		answer:
			"You can ask PDFquery questions or use keywords related to your search in your own words, and the tool smartly sifts through the PDF to highlight where the information is, like a digital assistant finding a needle in a haystack. It understands both exact terms and the meaning behind your words (semantics), simplifying your search without needing to flip every page.",
	},
	{
		category: "Use Cases",
		question:
			"Can PDFquery process documents and converse only in English language?",
		answer: (
			<span>
				<strong>Indian Languages:</strong> Hindi, Bengali, Telugu, Marathi,
				Tamil, Urdu, Gujarati, Kannada, Malayalam, Odia, Punjabi, Assamese,
				Maithili, Sanskrit
				<br />
				<br />
				<strong>Non-Indian Languages:</strong> English, Spanish, French, German,
				Chinese (Simplified and Traditional), Japanese, Korean, Italian,
				Russian, Portuguese, Dutch, Arabic, Turkish, Swedish, Danish, Norwegian,
				Finnish, Polish, Vietnamese, Thai, Indonesian, Greek, Czech, Romanian,
				Hungarian, Slovak, Bulgarian, Ukrainian, Hebrew
			</span>
		),
	},
	{
		category: "Account and Subscription",
		question: "How do I create a PDFquery account?",
		answer: "You can visit",
	},
	{
		category: "Account and Subscription",
		question: "What subscription plans are available?",
		answer:
			"PDFquery offers both free and premium subscription plans tailored to different user needs.",
	},
	{
		category: "Account and Subscription",
		question: "How do I upgrade to a premium plan?",
		answer:
			"You can upgrade to a paid plan by clicking “Change Plan” in your accounts settings.",
	},
	{
		category: "Account and Subscription",
		question: "What payment methods do you accept?",
		answer:
			"PDFquery accepts card payments through Visa, MasterCard, American Express (Excluding INR), Discover, JCB and UnionPay.",
	},
	{
		category: "Account and Subscription",
		question: "How can I cancel my subscription or delete my account?",
		answer:
			"You can cancel your paid subscription by selecting the free plan by clicking the badge inside your account and then selecting “Downgrade”. In case you wish to permanently delete your account, write to us at support@pdfquery.ai",
	},
	{
		category: "Security and Privacy",
		question:
			"How does PDFquery ensure the security and privacy of my documents?",
		answer:
			"PDFquery secures your documents by using encryption for data in transit and at rest, and ensures that your data isn't used for additional training, focusing on protecting your information against unauthorized access or misuse.",
	},
	{
		category: "Security and Privacy",
		question: "What data does PDFquery collect?",
		answer:
			"PDFquery collects essential information for service delivery and user experience enhancement, including email addresses, passwords, and payment details. This information helps in account setup, management, and personalized service provision.",
	},
	{
		category: "Security and Privacy",
		question: "Can I delete my data from PDFquery?",
		answer:
			"Yes, you can delete your data from PDFquery. You have the right to request the deletion of your personal data, including documents and account information. PDFquery will remove your associated payment details from their system within 7 days of the account deletion request.",
	},
	{
		category: "Integration and Compatibility",
		question:
			"Can I integrate PDFquery to any other tool or existing workflow?",
		answer:
			"Currently, PDFquery does not provide integration with any existing software. However, the roadmap for PDFquery entails many useful integrations.",
	},
	{
		category: "Integration and Compatibility",
		question: "Which file formats does PDFquery support?",
		answer:
			"Primarily focuses on PDFs; for additional formats, specific details were not provided.",
	},
	{
		category: "Support and Resources",
		question: "How can I contact support if I have a problem?",
		answer:
			"In case of any need of assistance, feel free to reach out to support@pdfquery.ai . We usually reply in 48 business hours.",
	},
	{
		category: "Support and Resources",
		question: "Are there any resources for developers using PDFquery?",
		answer:
			"Currently PDFquery has direct user interface applications. Developer resources will soon be made available after providing API delivery.",
	},
	{
		category: "Support and Resources",
		question:
			"Do you offer discounts for educational institutions or nonprofits?",
		answer:
			"PDFquery offers forever free account with all its features. Also, until 8th March, users can opt for a lifetime deal of 50% off with offer price of $7 per month.",
	},
	{
		category: "Troubleshooting and Common Issues",
		question: "What should I do if I encounter an error while using PDFquery?",
		answer: (
			<span>
				In need of any assistance, you can email{" "}
				<a href="mailto:support@pdfquery.ai" className="col1">
					support@pdfquery.ai
				</a>
				.
			</span>
		),
	},
	{
		category: "Troubleshooting and Common Issues",
		question: "Why is my document not processing correctly?",
		answer: (
			<span>
				The PDF processing might encounter errors in case of encryption,
				password protection or file corruption. Alternatively, make sure the
				file size of PDFs is as per your plan limits. In case of error or
				misinformation, kindly write to us at{" "}
				<a href="mailto:support@pdfquery.ai" className="col1">
					support@pdfquery.ai
				</a>
				.
			</span>
		),
	},
	{
		category: "Troubleshooting and Common Issues",
		question: "How can I improve the accuracy of data extraction?",
		answer:
			"To improve the accuracy of data extraction, ensure your input data is clean, well-structured, and presented with clear, detailed prompts that specify exactly what you need. Additionally, consider using few-shot learning by providing examples of the desired output to guide PDFquery towards more accurate results.",
	},
];

export default FAQData;
