import { Button } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const RBtn = (props) => {
  return (
    <>
      <Button
        variant="contained"
        className="rbtn"
        endIcon={<ArrowForwardIcon />}
        {...props}
      >
        {props.btn_name}
      </Button>
    </>
  );
};
