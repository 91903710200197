import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import ComparisonTableMUI from "./ComparisonTableMUI";

export const ComparisonTable = () => {
	return (
		<>
			<Box id="steps" className="subsection">
				<Container maxWidth="lg">
					<Box pb={3}>
						<Typography variant="h2" className="black fw4 al_center">
							<span className="lgrn">ChatGPT</span>{" "}
							<span className="lgry">vs</span> <span className="col1">PDF</span>
							query.ai
						</Typography>
					</Box>
					<Box pb={6}>
						<Grid
							container
							alignItems="center"
							justifyContent="space-between"
							spacing={4}
						>
							<Grid item lg={12} lg={12} sm={12} xs={12}>
								<Box className="">
									<ComparisonTableMUI />
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
};
