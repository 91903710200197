import Header from "./Component/Header/Header";
import "./Root.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import BackToTop from "./Component/Scroll/BackToTop";
import { Home } from "./Pages/Home";
import Terms from "./Pages/Terms";
import PrivacyPolicy from "./Pages/Privacy";
import CookiePolicy from "./Pages/Cookies";
import FAQ from "./Pages/FAQ/FAQ";
import DataSecurity from "./Pages/Security";

function App() {
	return (
		<>
			<Router>
				<Header />
				<BackToTop />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/privacy" element={<PrivacyPolicy />} />
					<Route path="/cookies" element={<CookiePolicy />} />
					<Route path="/security" element={<DataSecurity />} />
					<Route path="/frequently-asked-questions" element={<FAQ />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
