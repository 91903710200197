import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import Feature1 from "../../Assets/Images/Features/ai.webp";
import Feature2 from "../../Assets/Images/Features/multiple_file.webp";
import Feature3 from "../../Assets/Images/Features/extract_data.webp";
import Feature4 from "../../Assets/Images/Features/document _comparison.webp";
import Feature5 from "../../Assets/Images/Features/long _conversation.webp";
import Feature6 from "../../Assets/Images/Features/truth.webp";

export const FeaturesSection = () => {
	return (
		<>
			<Box id="hero" className="subsection">
				<Container maxWidth="lg">
					<Box pb={3}>
						<Typography variant="h2" className="black fw4 al_center">
							Upgrade Your Document Search{" "}
							<span className="col1">Experience with PDF</span>query
						</Typography>
					</Box>

					<Box pb={6} mt={4}>
						<Grid
							container
							alignItems="center"
							justifyContent="space-between"
							spacing={2}
						>
							<Grid item lg={4} lg={4} sm={6} xs={12}>
								<Box className="featureBx">
									<Box className="al_left">
										<img src={Feature1} className="feature_img" alt="AI" />
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h4" className="fw5">
											AI-Powered Search
										</Typography>
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h5" className="black">
											Just ask your PDFs any questions and get back exact
											answers. It's like having a smart search inside your
											documents.
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={4} lg={4} sm={6} xs={12}>
								<Box className="featureBx">
									<Box className="al_left">
										<img src={Feature2} className="feature_img" alt="AI" />
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h4" className="fw5">
											Multiple File Upload & Libraries
										</Typography>
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h5" className="black">
											Upload multiple files or build large libraries to extract
											insights across document{" "}
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={4} lg={4} sm={6} xs={12}>
								<Box className="featureBx">
									<Box className="al_left">
										<img src={Feature3} className="feature_img" alt="AI" />
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h4" className="fw5">
											Conclusive Analysis
										</Typography>
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h5" className="black">
											Get detailed insights with our advanced tools, including
											sentiment analysis supported by clear rationales.
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={4} lg={4} sm={6} xs={12}>
								<Box className="featureBx">
									<Box className="al_left">
										<img src={Feature4} className="feature_img" alt="AI" />
									</Box>
									<Box className="al_left " pt={2}>
										<Typography variant="h4" className="fw5">
											Document Comparison{" "}
											<sup className="text-xs text-red-500">Coming Soon</sup>
										</Typography>
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h5" className="black">
											Easily compare documents to track changes, identify
											differences, and analyze trends over time.
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={4} lg={4} sm={6} xs={12}>
								<Box className="featureBx">
									<Box className="al_left">
										<img src={Feature5} className="feature_img" alt="AI" />
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h4" className="fw5">
											Long Conversations
										</Typography>
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h5" className="black">
											Retains context for a long time, making it easy to
											maintain the conversational flow.
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={4} lg={4} sm={6} xs={12}>
								<Box className="featureBx">
									<Box className="al_left">
										<img src={Feature6} className="feature_img" alt="AI" />
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h4" className="fw5">
											Only Truth
										</Typography>
									</Box>
									<Box className="al_left" pt={2}>
										<Typography variant="h5" className="black">
											No hallucination or false answers. Provides accurate,
											contextual answers.
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
};
