import React, { useEffect, useState } from "react";
import axios from "axios";

import { Tooltip } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import config from "../../Utils/config";
import IconUpload from "../../Assets/Images/App/icon-upload.png";
import IconPDF from "../../Assets/Images/App/icon-file.png";
import IconRemovePDF from "../../Assets/Images/App/icon-remove.png";
import UploadBackground from "../../Assets/Images/App/image-background-upload.svg";
import RemoveFilesModal from "../../Component/Modals/remove-files-confirmation";
import FileLimitModal from "../../Component/Modals/file-limit";
import MobileUploadSection from "../../Component/MobileViewSections/M_UploadSection";
import TickIcon from "../../Assets/Images/App/icon-tick.svg";
import IconContinueArrow from "../../Assets/Images/App/button-continue.svg";
import SearchIcon from "../../Assets/Images/App/icon-search.svg";
import QuestionIcon from "../../Assets/Images/App/icon-question.svg";
import withSnackbar from "../../Component/Snackbar";
import BotImage from "../../Assets/Images/App/icon-bot.svg";
import IconSafeGuard from "../../Assets/Images/App/icon-safeguard.svg";
import IconTooltip from "../../Assets/Images/App/icon-tooltip.svg";
import JSZip from "jszip";
import Safeguard from "../../Assets/Images/Privacy/safeguard.svg";
import { logClarityEvent } from "../../Utils/clarity";

const FileUploadSection = ({
	setTab,
	setPreLoginId,
	generateUniqueNumber,
	setAuthFileName,
	setSnackState,
	uploadType,
	setUploadType,
	repositoryName,
	setRepositoryName,
}) => {
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openRemoveFilesModal, setOpenRemoveFilesModal] = useState(false);
	const [openFileLimitReachedModal, setOpenFileLimitReachedModal] =
		useState(false);
	const navigate = useNavigate();
	const [fileState, setFileState] = useState("ready");

	// Smol change
	const scrollToSection = (sectionId) => {
		const element = document.getElementById(sectionId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		setFiles([]);
	}, [uploadType]);

	const onDrop = (acceptedFiles) => {
		logClarityEvent("FilesUploaded", "FilesUploaded");
		logClarityEvent("FilesUploaded", {
			button_name: "FilesUploaded",
		});
		acceptedFiles.forEach((file) => {
			const fileType = file.type.split("/")[1];
			if (fileType === "pdf") {
				setFiles((prevFiles) => [...prevFiles, file]);
			} else if (fileType === "zip") {
				handleZip([file]);
			}
		});
	};

	const defaultAccept = {
		"application/pdf": [".pdf"],
	};

	// Define the accept object including both PDF and ZIP file types
	const libraryAccept = {
		"application/pdf": [".pdf"],
		"application/zip": [".zip"],
	};

	const accept = uploadType === "library" ? libraryAccept : defaultAccept;

	const defaultFileReq = {
		min: 1,
		max: 5,
	};
	const libraryFileReq = {
		min: 5,
		max: 10,
	};

	const fileLimit = uploadType === "library" ? libraryFileReq : defaultFileReq;

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept,
		maxSize: uploadType === "files" ? 10000000 : 1000000000,
	});

	const handleZip = async (files) => {
		const file = files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = async () => {
				try {
					const zip = await JSZip.loadAsync(reader.result);
					const fileNames = Object.keys(zip.files);
					const extractedFiles = [];
					for (const fileName of fileNames) {
						const zipFile = zip.files[fileName];
						const blob = await zipFile.async("blob");
						const extractedFile = new File([blob], fileName);
						extractedFiles.push(extractedFile);
					}
					setFiles((prevFiles) => [...prevFiles, ...extractedFiles]);
				} catch (error) {
					console.error("Error unzipping file(s):", error);
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	const removeFile = (indexToRemove) => {
		setFiles(files.filter((_, index) => index !== indexToRemove));
	};

	const uploadFile = async (presignedUrls) => {
		setLoading(true);
		try {
			const promises = files.map(async (file, index) => {
				return axios.put(presignedUrls[index], file);
			});

			await Promise.all(promises);

			setTab("chat");
			setLoading(false);
		} catch (error) {
			setFileState("error");
			setLoading(false);
		}
	};

	const getPresignedUrl = async (indexNameFromAPI) => {
		setFileState("loading");
		const noOfFiles = files.length;

		const fileNames = files.map((item) => item.name);
		const fileNameForAuth = files[0].name.slice(0, 5);
		setAuthFileName(fileNameForAuth);
		const uniqueIdentifier = generateUniqueNumber(fileNameForAuth); //always the fuirst file

		return axios
			.post(
				`${
					config.PDF_API_URL
				}/api/landing/upload/presigned-url/?no_of_files=${noOfFiles}&isLibrary=${
					uploadType === "library" ? "true" : "false"
				}`,
				fileNames,
				{
					headers: {
						"x-sc-key": `${fileNameForAuth};${uniqueIdentifier}`,
					},
				}
			)
			.then((response) => {
				const { data, status } = response;
				const presignedUrls = data.presigned_urls;
				const preLoginId = data.pre_login_id;
				setPreLoginId(preLoginId); //for chat
				return presignedUrls;
			})
			.catch((error) => {
				const status = error.response?.status;
				console.log(error.response);
				if (status === 400) {
					setOpenFileLimitReachedModal(true);
				}

				return [];
			})
			.finally(() => {
				return [];
			});
	};

	const onSubmit = async () => {
		logClarityEvent("UploadContinueButton", "UploadContinueButton");
		logClarityEvent("UploadContinueButton", {
			button_name: "UploadContinueButton",
		});
		const noOfFiles = files.length;
		if (!repositoryName && uploadType === "library") {
			setSnackState({
				message: `Please enter repository name`,
				status: 400,
			});
			return;
		}
		if (noOfFiles < fileLimit.min || noOfFiles > fileLimit.max) {
			setSnackState({
				message: `Please make sure that the no of files do not exceed ${fileLimit.max} and are not less than ${fileLimit.min}`,
				status: 400,
			});
			setFileState("error");
			return;
		}
		try {
			setFileState("loading");
			const presignedUrls = await getPresignedUrl();
			await uploadFile(presignedUrls);
		} catch (error) {
			console.log("ERROR", error);
			setFileState("error");
			setLoading(false);
		}
	};

	const getSlicedFileName = (fileName) => {
		const updatedName =
			fileName.length > 10 ? `${fileName.slice(0, 10)}...` : fileName;
		return updatedName;
	};

	const onCloseRemoveModal = () => setOpenRemoveFilesModal(false);

	const onConfirmRemoveFiles = () => {
		setFiles([]);
		setOpenRemoveFilesModal(false);
	};

	const handleRemoveFiles = () => setOpenRemoveFilesModal(true);

	const onCloseFileLimitModal = () => setOpenFileLimitReachedModal(false);

	const onUpgradeDueToFileLimit = () => {
		setOpenFileLimitReachedModal(false);
		handleOauthLogin();
	};

	const handleOauthLogin = () => {
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const url = `${OAUTH_URL}/oauth-v2/create-account-email?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery`;
		window.location.href = url;
	};

	return (
		<>
			<div className="hidden lg:flex">
				<div
					className="flex bg-cover w-full   lg:flex-row mt-8 " //m-12 was given
					style={{ backgroundImage: `url(${UploadBackground})` }}
				>
					<div className="w-[45%]  h-[67vh] mb-12 mx-8">
						<div className="p-6">
							<div>
								<h3>
									<span className="font-light">
										Discover Precision and Insights You Can Trust
									</span>{" "}
									{/* <strong className="text-red-600">PDF</strong>
									<span className="font-base">query</span> */}
								</h3>
							</div>
							<div>
								<ul className="flex lg:my-10">
									<li className="flex items-center mr-6">
										<div className="w-2 h-2 bg-black rounded-full mr-2"></div>
										<h4>Summarise</h4>
									</li>
									<li className="flex items-center mx-6">
										<div className="w-2 h-2 bg-black rounded-full mr-2"></div>
										<h4>Ask Anything</h4>
									</li>
									<li className="flex items-center mx-6">
										<div className="w-2 h-2 bg-black rounded-full mr-2"></div>
										<h4>Analyze</h4>
									</li>
								</ul>
							</div>
							<div>
								<div className="tracking-wide font-light">
									<div className="flex flex-row lg:mt-8  justify-left ">
										<img src={TickIcon} alt="" className="mr-2" />
										<h4>
											<strong className="font-semibold ">High Quality:</strong>{" "}
											<span>To-The-Point Answers</span>
										</h4>
									</div>
									<div className="flex flex-row lg:mt-8  justify-left">
										<img src={TickIcon} alt="" className="mr-2" />
										<h4>
											<strong className="font-semibold ">
												Reliable:{" "}
												{/* <sup>
													<button className="px-2 py-2   border bg-yellow-100 border-yellow-300 text-yellow-500 rounded-lg">
														New
													</button>
												</sup> */}
											</strong>
											<span>No Fake Responses</span>
										</h4>
									</div>
									<div className="flex flex-row lg:mt-8  justify-left ">
										<img src={TickIcon} alt="" className="mr-2" />
										<h4>
											<strong className="font-semibold">Insightful:</strong>{" "}
											<span className="capitalize">
												Collated insights from 100s of PDFs
												<sup>
													<button className="px-2 py-3  mx-2 border bg-green-50 border-gray-200 text-[#05C101] rounded-lg font-normal">
														New!
													</button>
												</sup>
											</span>
										</h4>
									</div>
									<div className="flex flex-row lg:mt-8  justify-left ">
										<img src={TickIcon} alt="" className="mr-2" />
										<h4>
											<strong className="font-semibold">Organized:</strong>{" "}
											<span className="capitalize">
												{" "}
												Create unlimited folders
												<sup>
													<button className="px-2 py-3  mx-2 border bg-green-50 border-gray-200 text-[#05C101] rounded-lg font-normal">
														New!
													</button>
												</sup>
											</span>
										</h4>
									</div>
									<div className="flex flex-row lg:mt-8  justify-left ">
										<img src={TickIcon} alt="" className="mr-2" />
										<h4>
											<strong className="font-semibold">Secure:</strong>{" "}
											<span className="capitalize">
												Your data remains in your control
											</span>
										</h4>
									</div>
									<div className="flex flex-row lg:mt-8  justify-left ">
										<img src={TickIcon} alt="" className="mr-2" />
										<h4>
											<strong className="font-semibold">Citations:</strong>{" "}
											<span className="capitalize">
												Source links and notes
												<sup>
													<button className="px-2 py-3  mx-2 border bg-[#FFEEC3] border-gray-200 text-[#DA9D02] rounded-lg font-normal">
														Coming Soon
													</button>
												</sup>
											</span>
										</h4>
									</div>
								</div>
							</div>
							{/* <div className="pb-2 mb-2">
								<div className="flex justify-start mt-8  ">
									<h5 className="flex flex-row text-black underline mt-2 mr-8">
										<img src={QuestionIcon} alt="" className="mr-1" />
										<Link onClick={() => scrollToSection("why-pdfquery")}>
											Why PDFquery?
										</Link>{" "}
									</h5>

									<h5 className="flex flex-row  sm:lg:text-black xl:text-red-600  underline mt-2">
										<img src={SearchIcon} alt="" className="mr-1" />
										<Link onClick={handleOauthLogin}>
											Explore for Free
										</Link>{" "}
									</h5>
								</div>
							</div> */}
						</div>
					</div>
					<div className="w-[55%]  h-[62vh] mb-4 p-6">
						<RemoveFilesModal
							isOpen={openRemoveFilesModal}
							onClose={onCloseRemoveModal}
							onConfirmRemove={onConfirmRemoveFiles}
						/>
						{/*End Modal */}
						{/*Start Modal */}
						<FileLimitModal
							isOpen={openFileLimitReachedModal}
							onClose={onCloseFileLimitModal}
							onUpgrade={onUpgradeDueToFileLimit}
						/>
						<p className=" font-bold leading-none text-center mb-8 text-[28px]">
							Chat With PDFs for free - No Card Needed!
						</p>
						<p className="flex items-center justify-center space-x-2 text-wrap font-roboto font-normal text-sm text-green-600 leading-none tracking-wide">
							<img src={Safeguard} alt="" />
							<p>We Safeguard Your Privacy.</p>
							<Link
								target="_blank"
								to={`/security`}
								className="underline cursor-pointer tracking-wide"
							>
								<span className="underline">Learn more</span>
							</Link>
						</p>

						<div className="bg-white w-[93%] h-[90%] rounded-3xl border border-dashed border-[#E21012] p-6 mt-8 ">
							<div className="flex items-center justify-between h-full">
								<div>
									<div className="max-w-md mx-auto space-y-6 mb-16">
										<h5 className="text-sm font-medium mb-4 text-[#332A2A]">
											Simple Steps To Use PDFquery For Interacting With PDFs
										</h5>
										<div className="flex items-center mb-4">
											<div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-white text-red-600 border border-red-600 rounded-full">
												1
											</div>
											<div className="ml-4">
												<h5 className="flex items-center space-x-1 font-semibold">
													<span>Create</span>
													<span className="font-normal">a collection name</span>
													<Tooltip
														title="A collection is your personal set of PDF documents"
														placement="right"
													>
														<img src={IconTooltip} alt="Tooltip icon" />
													</Tooltip>
												</h5>
											</div>
										</div>
										<div className="flex items-center mb-4">
											<div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-white text-red-600 border border-red-600 rounded-full">
												2
											</div>
											<div className="ml-4">
												<h5 className="font-semibold">
													Upload{" "}
													<span className="font-normal">
														your PDFs in the collection
													</span>
												</h5>
												<h5 className="flex items-center space-x-2 text-green-600 pt-1">
													{/* <img src={IconSafeGuard} alt="" /> */}
													{/* <span className="font-normal font-roboto text-xs">
														We safeguard your privacy.{" "}
														<span
															className="underline"
															onClick={() => navigate("/security")}
														>
															Learn more
														</span>
													</span> */}
												</h5>
											</div>
										</div>
										<div className="flex items-center">
											<div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-white text-red-600 border border-red-600 rounded-full">
												3
											</div>
											<div className="ml-4">
												<h5 className="font-semibold">
													Start conversing{" "}
													<span className="font-normal">with your PDFs!</span>
												</h5>
											</div>
										</div>
									</div>
								</div>
								<div className="flex flex-col space-y-8">
									<button
										className="px-10 py-1 bg-[#E21012] text-white text-nowrap rounded-md text-lg transition-all duration-200"
										onClick={handleOauthLogin}
									>
										Try for FREE
									</button>
									<img src={BotImage} alt="" height={117} width={137} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="lg:hidden">
				<MobileUploadSection
					getInputProps={getInputProps}
					getRootProps={getRootProps}
					files={files}
					removeFile={removeFile}
					handleRemoveFiles={handleRemoveFiles}
					onSubmit={onSubmit}
					loading={loading}
					repositoryName={repositoryName}
					setRepositoryName={setRepositoryName}
				/>
			</div>
		</>
	);
};

export default withSnackbar(FileUploadSection);
