"use client";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import config from "../../Utils/config";
import axios from "axios";
import ProfileIcon from "../../Assets/Images/App/profile-pic.png";
import ForcedRegistrationModal from "../../Component/Modals/forced-registration-modal";
import IconSendChat from "../../Assets/Images/App/icon-send-chat.png";
import ToggleOpenIcon from "../../Assets/Images/App/icon-open-document.svg";
import ToggleCloseIcon from "../../Assets/Images/App/icon-close-document.svg";
import PopupComponent from "../../Component/Modals/show-document";
import BackIcon from "../../Assets/Images/App/icon-back.svg";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logClarityEvent } from "../../Utils/clarity";

const suggestedQuestions = [
	"Could you provide a brief summary of the content in this PDF file?",
	"What are the main topics or subjects covered in this PDF?",
	"Is the content of this PDF file relevant to my interests or needs?",
	"Are there any insights or key points in this PDF that I should be aware of?",
];

const exampleConvo = [
	{
		user: "Hi there! Did you know that PDF files are one of the most popular formats for sharing documents?",
		bot: "Yes, I've heard about PDFs. They're great for preserving the formatting of documents across different devices and platforms.",
		thumbed_up: false,
	},
	{
		user: "Exactly! Plus, they're widely used for things like contracts, reports, and forms.",
		bot: "That's true. PDFs are commonly used in business and academic settings because they provide a consistent appearance regardless of the software used to create them.",
		thumbed_up: false,
	},
	{
		user: "I've also heard that PDF files can contain interactive elements like hyperlinks and forms.",
		bot: "Yes, that's correct. PDFs can include hyperlinks to websites, email addresses, and other documents, making them versatile for various purposes.",
		thumbed_up: false,
	},
	{
		user: "Interesting! Do you know if there are any downsides to using PDF files?",
		bot: "Well, one potential downside is that editing PDFs can be more challenging compared to other document formats like Word documents. However, there are many tools available for editing PDFs nowadays.",
		thumbed_up: false,
	},
	{
		user: "Got it. Thanks for the information!",
		bot: "You're welcome! If you ever have any more questions about PDF files or anything else, feel free to ask.",
		thumbed_up: false,
	},
];

const ChatPageSection = ({
	setTab,
	preLoginId,
	uniqueIdentifier,
	authFileName,
	uploadType,
	repositoryName,
	noOfFiles,
}) => {
	const navigate = useNavigate();
	const [userQuery, setUserQuery] = useState("");
	const [suggestedQuestionsFromAPI, setSuggestedQuestionsFromAPI] = useState(
		[]
	);
	const [conversation, setConversation] = useState([]);
	const chatContainerRef = useRef;

	const [iframeSrc, setIframeSrc] = useState(null);
	const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);

	const [mobViewShowDocument, setMobViewShowDocument] = useState(false);

	//Modal
	const [openForcedRegistrationModal, setOpenForcedRegistrationModal] =
		useState(false);
	const [email, setEmail] = useState("");

	const onClose = () => setOpenForcedRegistrationModal(false);

	const handleDocumentSwitch = (index) => {
		setCurrentDocumentIndex(index);
	};

	const toggleDocument = () => {
		setMobViewShowDocument((prev) => !prev);
	};

	const handleEmailCapture = async () => {
		try {
			const response = await axios.post(
				`${config.PDF_API_URL}/api/support/contact-us/`,
				{
					email,
					name: email.split("@")[0],
					description: "Email Capture",
				}
			);
			console.log("Email Not Captured");
		} catch (error) {
			console.log("Email Not Captured");
		}
	};

	//Login Request
	const onLogin = async (userEmail = "default") => {
		logClarityEvent("SignUpViaForcedRegistrationModal", {
			button_name: userEmail,
		});
		logClarityEvent("SignUpViaForcedRegistrationModal", userEmail);
		await handleEmailCapture();
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;

		const url = `${OAUTH_URL}/oauth-v2/create-account-email?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&email=${email}&appName=${`pdfquery`}`;
		const cookieData = {
			pre_login_id: preLoginId,
			user_upload_type: uploadType,
			repository_name: uploadType === "library" ? repositoryName : null,
			no_of_files: noOfFiles,
		};
		Cookies.set("preSignedInUser", JSON.stringify(cookieData), {
			domain: ".pdfquery.ai",
			expires: new Date(Date.now() + 72 * 60 * 60 * 1000), //72h
		});

		window.location.href = url;
	};

	//Document JS
	const getFileName = (url) => {
		const pathname = new URL(url).pathname;
		const encodedFileName = pathname.split("/").pop();
		const fileName = decodeURIComponent(encodedFileName);
		const slicedFileName =
			fileName.length > 10 ? `${fileName.slice(0, 10)}...` : fileName;
		return slicedFileName;
	};

	const getFileTooltip = (url) => {
		const pathname = new URL(url).pathname;
		const encodedFileName = pathname.split("/").pop();
		const fileName = decodeURIComponent(encodedFileName);
		return fileName;
	};
	//query
	useEffect(() => {
		chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [conversation]);

	const handleInputFocus = () => {
		chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	// const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	//   setUserQuery(e.target.value);
	// };

	const handleQueryChange = (e) => {
		setUserQuery(e.target.value);
	};

	const handleMessageSubmit = () => {
		logClarityEvent("TypeQuestion", { button_name: "TypeQuestion" });
		logClarityEvent("TypeQuestion", "TypeQuestion");
		setOpenForcedRegistrationModal(true);
		// setUserQuery("");
	};

	useEffect(() => {
		scrollToBottom();
	}, [conversation]);

	const handleKeyDown = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleMessageSubmit();
		}
	};

	const scrollToBottom = () => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop =
				chatContainerRef.current.scrollHeight;
		}
	};

	const handleSuggestedInsert = (aiSuggestedQuestion) => {
		setConversation((prevConversation) => [
			...prevConversation,
			{ user: aiSuggestedQuestion, bot: "", thumbed_up: false },
		]);
		// getAPIResponse(aiSuggestedQuestion);
		setUserQuery("");
	};

	const getStoredFiles = async () => {
		try {
			const { data } = await axios.get(
				`${
					config.PDF_API_URL
				}/api/landing/download/presigned-url/?pre_login_id=${preLoginId}&isLibrary=${
					uploadType === "library" ? "true" : "false"
				}`,
				{
					headers: {
						"x-sc-key": `${authFileName};${uniqueIdentifier}`,
					},
				}
			);
			setIframeSrc(data.presigned_urls);
			getSuggestedQuestions(data.file_ids);
		} catch (error) {
			// console.log(error);
		}
	};

	const getSuggestedQuestions = async (fileIds) => {
		const queryString = fileIds.map((id) => `file_id=${id}`).join("&");

		try {
			const { data } = await axios.get(
				`${
					config.PDF_API_URL
				}/api/landing/questions/?pre_login_id=${preLoginId}&${queryString}&isLibrary=${
					uploadType === "library" ? "true" : "false"
				}`,
				{
					headers: {
						"x-sc-key": `${authFileName};${uniqueIdentifier}`,
					},
				}
			);
			setSuggestedQuestionsFromAPI(data.questions);
		} catch (error) {
			// console.log(error);
		} finally {
		}
	};

	useEffect(() => {
		getStoredFiles();
	}, []);

	return (
		<>
			<ForcedRegistrationModal
				isOpen={openForcedRegistrationModal}
				onClose={onClose}
				onLogin={onLogin}
				setEmail={setEmail}
				email={email}
			/>
			<div className="flex px-3 py-4 lg:ml-12" onClick={() => setTab("upload")}>
				<img src={BackIcon} alt="" />
				<h5 className="pl-4 font-semibold text-center cursor-pointer underline">
					Back to Main
				</h5>
			</div>
			<div className="lg:hidden flex mx-2 overflow-hidden ">
				{iframeSrc?.map((item, index) => (
					<button
						key={item}
						title={getFileTooltip(item)}
						onClick={() => handleDocumentSwitch(index)}
						className={`p-4 mb-2 mr-2 rounded-lg bg-white ${
							currentDocumentIndex === index
								? `border-red-600 text-red-600`
								: `border-gray-300 text-black`
						} border  transition-colors duration-300 `}
					>
						{getFileName(item)}
					</button>
				))}
			</div>
			<div className="lg:hidden z-20 absolute mt-12">
				<img
					src={mobViewShowDocument ? ToggleCloseIcon : ToggleOpenIcon}
					alt=""
					onClick={toggleDocument}
				/>
			</div>
			<div className="flex flex-col lg:bg-none bg-gray-100 lg:p-0 p-4 lg:mx-12  my-4 lg:rounded-xl lg:border-none border border-gray-300">
				{/* MODAL */}

				{/* CONTENT SEC */}
				<div className="flex flex-col lg:flex-row">
					{/* <div
						className={`lg:hidden ${
							mobViewShowDocument ? "flex" : "hidden"
						} lg:w-1/2 h-[calc(100vh-13rem)] flex-col hover:border-custom-red  transition-colors duration-300`}
					>
						<div className="lg:hidden h-[90%]  border rounded-lg shadow  overflow-auto">
							{iframeSrc?.map((item, index) => (
								// eslint-disable-next-line jsx-a11y/iframe-has-title
								<iframe
									key={item}
									src={item}
									className={`w-full h-full border-none ${
										currentDocumentIndex === index ? "block" : "hidden"
									}`}
								></iframe>
							))}
						</div>
					</div> */}
					<PopupComponent
						currentDocumentIndex={currentDocumentIndex}
						iframeSrc={iframeSrc}
						showDocument={mobViewShowDocument}
						toggleDocument={toggleDocument}
					/>
					<div
						className={`hidden lg:flex lg:w-1/2 h-[calc(100vh-13rem)] lg:p-1 p-4 bg-white flex-col  border hover:border-custom-red  transition-colors duration-300`}
					>
						{/*These lil buttons for switching between documents */}
						<div className="flex justify-center  space-x-4">
							{iframeSrc?.map((item, index) => (
								<button
									key={item}
									title={getFileTooltip(item)}
									onClick={() => handleDocumentSwitch(index)}
									className={`p-2 mb-2 mr-2 rounded-lg bg-white ${
										currentDocumentIndex === index
											? `border-red-600 text-red-600`
											: `border-gray-300 text-black`
									} border  transition-colors duration-300 `}
								>
									<h6>{getFileName(item)}</h6>
								</button>
							))}
						</div>

						{/* Document content */}
						<div className="h-[90%] bg-white lg:border-non border rounded-lg shadow lg:p-0 p-4  overflow-auto">
							{iframeSrc?.map((item, index) => (
								// eslint-disable-next-line jsx-a11y/iframe-has-title
								<iframe
									key={item}
									src={item}
									className={`w-full h-full border-none ${
										currentDocumentIndex === index ? "block" : "hidden"
									}`}
								></iframe>
							))}
						</div>
					</div>

					{/* Chat section */}
					<div
						className={`w-full lg:w-1/2 lg:h-[calc(100vh-13rem)] h-[calc(100vh-15rem)] lg:p-4 lg:bg-white flex flex-col lg:border hover:border-custom-red  transition-colors duration-300`}
					>
						<div className="p-2 flex-1 relative overflow-hidden">
							{/* Other content */}
							<div className="absolute bottom-0 left-0 w-full">
								<div className="flex flex-col justify-center items-left p-4 lg:p-8 lg:p-36">
									<img
										className="ml-[40%]  my-4 flex items-start justify-start w-10 h-10 rounded-full border border-red-600 bg-red-200 "
										src={ProfileIcon}
										alt="userProfile"
										width={8}
										height={8}
									/>
									<p className="p-2 text-center mb-[30%] text-black font-normal ">
										How can I help you?
									</p>
								</div>
								{suggestedQuestionsFromAPI.length > 0 && (
									<div className="pr-6 text-gray-400 text-sm">
										<p>Suggested Questions</p>
									</div>
								)}
								<div className="w-full py-4 grid lg:grid-cols-2 grid-rows-2 gap-4">
									{suggestedQuestionsFromAPI.map((item) => (
										<div
											key={item}
											onClick={handleMessageSubmit}
											className="border border-gray-300 lg:p-4 p-2 bg-white lg:bg-gray-100 rounded-lg lg:text-center hover:border-gray-700 transition-all duration-300 cursor-pointer"
										>
											<h6
												onClick={() => handleSuggestedInsert(item)}
												className="hover:text-custom-red transition-all duration-300"
											>
												{item}
											</h6>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="relative ">
							<input
								className="w-full lg:p-2 px-2 py-3 pr-10 border border-solid border-gray-300 rounded-lg bg-white focus:outline-none focus:border-custom-red focus:ring-2 focus:ring-red-600 animate__animated animate__fadeIn animate__fast animate__repeat-1 shadow-lg transition-all duration-300"
								placeholder="Start asking question to pdfquery.ai .."
								value={userQuery}
								onChange={handleQueryChange}
								onKeyDown={handleKeyDown}
								onFocus={handleInputFocus}
							/>
							<button
								onClick={handleMessageSubmit}
								className="absolute top-1/2 right-0 transform -translate-y-1/2 focus:outline-none"
							>
								<img
									src={IconSendChat}
									alt="Send"
									className="w-16 h-[68px] mt-2 ml-[16%] lg:w-12 lg:h-12 lg:mt-1 lg:ml-[12%]"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ChatPageSection;
