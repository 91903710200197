import React from "react";
import TickIcon from "../../Assets/Images/App/icon-tick.svg";

function PointersSection() {
	return (
		<div className="p-4 mx-4 my-4 bg-white rounded-lg">
			<div className="tracking-wide font-light space-y-4 ">
				<div className="flex flex-row   justify-left ">
					<img src={TickIcon} alt="" className="mr-2" />
					<p className="text-lg">
						<strong className="font-semibold ">High Quality:</strong>{" "}
						<span className="capitalize">To-the-point answers</span>
					</p>
				</div>
				<div className="flex flex-row mt-3  justify-left">
					<img src={TickIcon} alt="" className="mr-2" />
					<p className="text-lg">
						<strong className="font-semibold ">
							Reliable:{" "}
							{/* <sup>
													<button className="px-2 py-2   border bg-yellow-100 border-yellow-300 text-yellow-500 rounded-lg">
														New
													</button>
												</sup> */}
						</strong>
						<span>No Fake Responses</span>
					</p>
				</div>
				<div className="flex flex-row mt-3  justify-left ">
					<img src={TickIcon} alt="" className="mr-2" />
					<p className="text-lg">
						<strong className="font-semibold">Insightful:</strong>{" "}
						<span className="capitalize">
							Collated insights from 100s of PDFs
							<sup>
								<button className="px-2 py-3  mx-2 border bg-green-50 border-gray-200 text-[#05C101] rounded-lg font-normal">
									New!
								</button>
							</sup>
						</span>
					</p>
				</div>
				<div className="flex flex-row mt-3  justify-left ">
					<img src={TickIcon} alt="" className="mr-2" />
					<p className="text-lg">
						<strong className="font-semibold">Organized:</strong>{" "}
						<span className="capitalize">
							{" "}
							Create unlimited <br />
							folders
							<sup>
								<button className="px-2 py-3  mx-2 border bg-green-50 border-gray-200 text-[#05C101] rounded-lg font-normal">
									New!
								</button>
							</sup>
						</span>
					</p>
				</div>
				<div className="flex flex-row mt-3  justify-left ">
					<img src={TickIcon} alt="" className="mr-2" />
					<p className="text-lg">
						<strong className="font-semibold">Secure:</strong>{" "}
						<span className="capitalize">
							Your data remains in your control
						</span>
					</p>
				</div>
				<div className="flex flex-row mt-3  justify-left ">
					<img src={TickIcon} alt="" className="mr-2" />
					<p className="text-lg">
						<strong className="font-semibold">Citations:</strong>{" "}
						<span className="capitalize">
							Source links and <br /> notes
							<sup>
								<button className="px-2 py-3  mx-2 border bg-[#FFEEC3] border-gray-200 text-[#DA9D02] rounded-lg font-normal">
									Coming Soon
								</button>
							</sup>
						</span>
					</p>
				</div>
			</div>
		</div>
	);
}

export default PointersSection;
