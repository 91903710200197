import * as React from "react";
import { useState } from "react";
import { Drawer, IconButton, Divider, Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import config from "../../Utils/config";
import Cookies from "js-cookie";

export default function MobileDrawer() {
	const [state, setState] = useState({ right: false });
	const navigate = useNavigate();

	const isActive = (paths) => {
		return paths.some((path) => window.location.hash === path);
	};
	const toggleDrawer = (anchor, open) => () => {
		setState({ ...state, [anchor]: open });
	};

	const isDrawerOpen = state["right"];

	const DrawerHeader = styled("div")(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	}));

	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const scrollToSection = (sectionId) => {
		const element = document.getElementById(sectionId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	const handleButtonClick = (sectionId) => {
		scrollToSection(sectionId);
		toggleDrawer("right", false)();
		navigate(`#${sectionId}`);
	};

	//   const scrollToSection = (sectionId) => {
	//     const element = document.getElementById(sectionId);
	//     if (element) {
	//       element.scrollIntoView({ behavior: 'smooth' });
	//     }
	//   }

	const getURL = () => {
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const preAccessToken = Cookies.get("_pre_accessToken");
		if (preAccessToken)
			return `${OAUTH_URL}/oauth-v2/check-account?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery&preAccessToken=${preAccessToken}`;
		else
			return `${OAUTH_URL}/oauth-v2/login?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery`;
	};

	const handleOauthLogin = () => {
		toggleDrawer("right", false);
		const url = getURL();
		window.location.href = url;
	};

	return (
		<>
			<IconButton
				className="drawer_btn"
				size="medium"
				onClick={toggleDrawer("right", !isDrawerOpen)}
			>
				{isDrawerOpen ? (
					<CloseIcon fontSize="small" />
				) : (
					<MenuIcon fontSize="small" />
				)}
			</IconButton>

			<Drawer
				id="mobile-drawer"
				anchor="right"
				open={isDrawerOpen}
				onClose={toggleDrawer("right", false)}
			>
				<DrawerHeader>
					<IconButton
						className=""
						variant="contained"
						onClick={toggleDrawer("right", false)}
					>
						<CloseIcon fontSize="small" className="col1" />
					</IconButton>
				</DrawerHeader>

				<Button
					fullWidth
					color="error"
					variant="contained"
					className="main-btn-2"
					style={{
						maxWidth: "80%",
						marginLeft: 20,
						marginBottom: 10,
						borderRadius: 8,
					}}
					onClick={handleOauthLogin}
				>
					{" "}
					Sign in / Sign up{" "}
				</Button>

				<Accordion
					expanded={expanded === "panel1"}
					onChange={handleChange("panel1")}
				>
					<AccordionSummary
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Button
							variant="text"
							className={`${isActive(["#pricing"]) ? "menu_active" : "menus"}`}
							disableRipple
							fullWidth
							onClick={() => handleButtonClick("pricing")}
						>
							{" "}
							Pricing{" "}
						</Button>
					</AccordionSummary>
				</Accordion>

				<Accordion
					expanded={expanded === "panel2"}
					onChange={handleChange("panel2")}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2bh-content"
						id="panel2bh-header"
					>
						<Button variant="text" className="menus" disableRipple fullWidth>
							{" "}
							Information{" "}
						</Button>
					</AccordionSummary>
					<AccordionDetails>
						<Stack direction="column" spacing={1}>
							<Button
								variant="text"
								className={`subMenus ${
									isActive(["#why-pdfquery"]) ? "menu_active" : "menus"
								}`}
								disableRipple
								onClick={() => handleButtonClick("why-pdfquery")}
							>
								{" "}
								Why PDFquery?{" "}
							</Button>
							<Button
								variant="text"
								className={`subMenus ${
									isActive(["#how-it-works"]) ? "menu_active" : "menus"
								}`}
								disableRipple
								onClick={() => handleButtonClick("how-it-works")}
							>
								{" "}
								How it works?{" "}
							</Button>
							<Button
								variant="text"
								className={`subMenus ${
									isActive(["#who-can-use-it"]) ? "menu_active" : "menus"
								}`}
								disableRipple
								onClick={() => handleButtonClick("who-can-use-it")}
							>
								{" "}
								Who can use it?{" "}
							</Button>
							<Button
								variant="text"
								className={`subMenus ${
									isActive(["#compare-with-chatgpt"]) ? "menu_active" : "menus"
								}`}
								disableRipple
								onClick={() => handleButtonClick("compare-with-chatgpt")}
							>
								{" "}
								Compare with ChatGPT{" "}
							</Button>
						</Stack>
					</AccordionDetails>
				</Accordion>

				<Accordion
					expanded={expanded === "panel3"}
					onChange={handleChange("panel3")}
				>
					<AccordionSummary
						aria-controls="panel3bh-content"
						id="panel3bh-header"
					>
						<Button
							variant="text"
							className={`${
								isActive(["#contact-us"]) ? "menu_active" : "menus"
							}`}
							disableRipple
							fullWidth
							onClick={() => handleButtonClick("contact-us")}
						>
							{" "}
							Contact Us{" "}
						</Button>
					</AccordionSummary>
				</Accordion>

				<Accordion
					expanded={expanded === "panel3"}
					onChange={handleChange("panel3")}
				>
					<AccordionSummary
						aria-controls="panel3bh-content"
						id="panel3bh-header"
					>
						<Button
							variant="text"
							className={`${isActive(["#faq"]) ? "menu_active" : "menus"}`}
							disableRipple
							fullWidth
							onClick={() => handleButtonClick("faq")}
						>
							{" "}
							FAQs{" "}
						</Button>
					</AccordionSummary>
				</Accordion>

				<Divider />
			</Drawer>
		</>
	);
}
