import { IconButton, CircularProgress, styled } from "@mui/material";
import React, { useState, useEffect } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ButtonContainer = styled("div")({
	position: "fixed",
	bottom: "40px",
	right: "30px",
	alignItems: "center",
	justifyContent: "center",
	zIndex: 1000,
	cursor: "pointer",
	animation: "fadeIn 0.3s",
	opacity: ({ isScrollButtonVisible }) => (isScrollButtonVisible ? 1 : 0.5),
	display: ({ isScrollButtonVisible }) =>
		isScrollButtonVisible ? "flex" : "none",
});

const StyledCircularProgress = styled(CircularProgress)({
	position: "absolute",
});

const StyledIconButton = styled(IconButton)({
	backgroundColor: "#d9d9d9",
	position: "absolute",
	top: "50%",
	left: "50%",
	padding: "10px",
	zIndex: 4,
	transform: "translate(-50%, -50%)",
});

export default function BackToTop() {
	const [scroll, setScroll] = useState(0);
	const [showButton, setShowButton] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);

	useEffect(() => {
		const checkScrollHeight = () => {
			const currentPosition = window.pageYOffset;
			if (!showButton && currentPosition > 0) {
				setShowButton(true);
			} else if (showButton && currentPosition <= 0) {
				setShowButton(false);
			}
			setScrollPosition(currentPosition);
		};

		window.addEventListener("scroll", checkScrollHeight);
		return () => {
			window.removeEventListener("scroll", checkScrollHeight);
		};
	}, [showButton]);

	useEffect(() => {
		let progressBarHandler = () => {
			const totalScroll = document.documentElement.scrollTop;
			const windowHeight =
				document.documentElement.scrollHeight -
				document.documentElement.clientHeight;
			const scroll = (totalScroll / windowHeight) * 100;
			setScroll(scroll);
		};

		window.addEventListener("scroll", progressBarHandler);

		return () => window.removeEventListener("scroll", progressBarHandler);
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<ButtonContainer isScrollButtonVisible={showButton} onClick={scrollToTop}>
			<StyledCircularProgress
				size={50}
				variant="determinate"
				value={scroll}
				style={{ color: "#fc0000" }}
			/>
			<StyledIconButton>
				<KeyboardArrowUpIcon fontSize="medium" />
			</StyledIconButton>
		</ButtonContainer>
	);
}
