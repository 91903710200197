import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Container } from "@mui/material";
import BackIcon from "../../Assets/Images/App/icon-back.svg";
import { useNavigate } from "react-router-dom";

const headerStyles = {
	backgroundColor: "#e31212",
	padding: "16px",
	textAlign: "center",
	marginBottom: "32px",
	paddingTop: "100px",
};

const TermsOfService = () => {
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	const navigate = useNavigate();

	useEffect(() => scrollToTop(), []);

	return (
		<div>
			<div className="relative bg-red-600 p-4 text-center mb-8 pt-24">
				<button
					onClick={() => navigate("/")}
					className="md:block hidden absolute left-4 top-12 md:left-8 md:top-28 bg-white md:py-4 md:px-2 text-red-600 px-4 py-2 rounded-lg"
				>
					Back
				</button>
				<button
					onClick={() => navigate("/")}
					className="md:hidden block absolute left-4 top-24 md:left-8 md:top-28 bg-white md:py-4 md:px-2 text-red-600 px-4 py-2 rounded-lg"
				>
					<img src={BackIcon} alt="" />
				</button>
				<h1 className="text-white">Terms of Services</h1>
			</div>
			<Container>
				<Typography variant="body1">
					<strong>1. Introduction</strong>
					<br />
					Welcome to PDFquery! We prioritize your privacy and are dedicated to
					protecting it. This Privacy Policy provides details on the information
					we collect, its usage, the security measures in place, and your rights
					regarding your personal data. By using our web application, you agree
					to the terms outlined in this policy.
				</Typography>

				<Typography variant="body1">
					<strong>2. Service Description</strong>
					<br />
					PDFquery offers a unique service that allows users to interact with
					PDF files, leveraging the power of GPT-4 to enhance document
					accessibility and data comprehension. Our service is subject to
					certain limitations on the size and types of files that can be
					processed. For detailed information on these restrictions and our
					subscription plans, please visit our website{" "}
					<Link href="https://pdfquery.ai/">pdfquery.ai</Link>.
				</Typography>
				<br />
				<Typography variant="body1">
					<strong>3. User Responsibilities</strong>
					<br />
					To access our services, users are required to provide an email
					address, and in the future, a phone number may be collected for
					account creation. Sign-in is facilitated through passwords, hence
					passwords will be encrypted and stored for facilitating sign-ins.
					Users consent to receive emails necessary for service operation.
					<br />
					Documents and chat histories are stored to improve user experience but
					can be deleted at the user's discretion. While we strive to enhance
					our service through user interactions, we integrate with third-party
					vendors, including Fastspring for payment processing, Google
					Analytics, Microsoft Clairty for usage analysis, and ChatGPT for chat
					responses. We assume no responsibility for data handled by these
					vendors; users are encouraged to review their privacy policies.
					Discontinuation of our service is advised if users disagree with these
					terms or our vendors' policies.
				</Typography>
				<br />
				<Typography variant="body1">
					<strong>4. Content Ownership and Intellectual Property Rights</strong>
					<br />
					Users retain full ownership of the content they upload. PDFquery does
					not claim any rights over the processed data. The technology and
					software underlying our service are the proprietary material of
					PDFquery, protected under copyright laws.
				</Typography>
				<br />
				<Typography variant="body1">
					<strong>5. Privacy Policy</strong>
					<br />
					Our commitment to privacy is unwavering. The user data we collect,
					store, and the way we handle it is detailed in our{" "}
					<Link href="https://pdfquery.ai/privacy">privacy policy</Link>. This
					includes information on document and chat storage, as well as our
					engagement with third-party vendors.
				</Typography>
				<br />
				<Typography variant="body1">
					<strong>6. Subscription Model and Modifications</strong>
					<br />
					We operate on a subscription model. Changes to our offerings or
					features will be communicated to existing users via email and updated
					on our website for prospective users. Users are encouraged to stay
					informed about any modifications that may affect their service
					experience.
				</Typography>
				<br />
				<Typography variant="body1">
					<strong>7. User Restrictions</strong>
					<br />
					Our fair usage policy prohibits the creation of multiple free accounts
					and the excessive submission of queries. We adhere to ChatGPT's
					guidelines on content legality and sensitivity, distancing ourselves
					from responsibility related to content restrictions.
				</Typography>
				<br />
				<Typography variant="body1">
					<strong>8. Support and Dispute Resolution</strong>
					<br />
					Should any disputes or issues arise, users are encouraged to contact
					us via email. Our dedicated team will address your concerns promptly
					and efficiently.
				</Typography>
				<br />
				<Typography variant="body1">
					<strong>9. Governing Law</strong>
					<br />
					These Terms of Service are governed by the laws of jurisdiction of the
					courts in the city of London, United Kingdom, without regard to its
					conflict of law provisions. Our failure to enforce any right or
					provision will not be considered a waiver of those rights.
				</Typography>
				<br />
				<Typography variant="body1">
					<strong>10. Agreement to Terms</strong>
					<br />
					By accessing or using our service, you agree to be bound by these
					Terms. If you disagree with any part of the terms, you may not access
					the service.
					<br />
					<br />
					These Terms of Service are effective as of 16th February 2024 and will
					remain in effect except with respect to any changes in its provisions
					in the future, which will be in effect immediately after being posted
					on this page.
					<br />
					<br />
					We reserve the right to update or change our Terms of Service at any
					time, and you should check this Terms of Service periodically. Your
					continued use of the Service after we post any modifications to the
					Terms of Service on this page will constitute your acknowledgment of
					the modifications and your consent to abide and be bound by the
					modified Terms of Service.
				</Typography>
			</Container>
		</div>
	);
};

export default TermsOfService;
