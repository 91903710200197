import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";

import Step1 from "../../Assets/Images/Steps/step11.png";
import Step2 from "../../Assets/Images/Steps/step22.png";
import Step3 from "../../Assets/Images/Steps/step33.png";

export const StepsSection = () => {
	return (
		<>
			<Box id="steps" className="subsection sec_bg_2">
				<Container maxWidth="lg">
					<Box pb={3}>
						<Typography variant="h2" className="black fw4 al_center">
							Use It in <span className="col1">3 Simple Steps</span>
						</Typography>
					</Box>
					<Box pb={6}>
						<Grid
							container
							alignItems="center"
							justifyContent="space-between"
							spacing={4}
						>
							<Grid item lg={3} lg={4} sm={6} xs={12}>
								<Box className="stepBx">
									<Box className="flex al_center justify-center">
										<img src={Step1} className="step_img" alt="AI" />
									</Box>
									<Divider />
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Upload the Documents
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} lg={4} sm={6} xs={12}>
								<Box className="stepBx">
									<Box className="flex al_center justify-center">
										<img src={Step2} className="step_img" alt="AI" />
									</Box>
									<Divider />
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Ask Your Desired Questions
										</Typography>
									</Box>
								</Box>
							</Grid>

							<Grid item lg={3} lg={4} sm={6} xs={12}>
								<Box className="stepBx">
									<Box className="flex al_center justify-center">
										<img src={Step3} className="step_img" alt="AI" />
									</Box>
									<Divider />
									<Box className="al_center" pt={2}>
										<Typography variant="h5" className="fw5">
											Get Instant Insights
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
};
