import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

// import Hero from "../../Assets/Images/hero.png";
import { RBtn } from "../../Component/Buttons/RBtn";
import { Link } from "react-router-dom";

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import config from "../../Utils/config";
// import "react-modal-video/scss/modal-video.scss";

export const VideoSection = () => {
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	};

	const handleOauthLogin = () => {
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const url = `${OAUTH_URL}/oauth-v2/create-account-email?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery`;
		window.location.href = url;
	};

	return (
		<>
			<Box className="relative">
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
					spacing={0}
				>
					<Grid item lg={12} sm={12} xs={12}>
						<Box className="video_bg">
							<Link>
								<Box className="play_btn_1" onClick={openModal}></Box>
							</Link>
						</Box>
						<Box className="vdo_btm_content" pt={5}>
							<Box className="al_center">
								<Typography variant="h4" className="black fw5" gutterBottom>
									“<span className="col1">PDF</span>query saves hours worth of
									effort and gives instant insight when needed.”
								</Typography>
								<Typography variant="h4" className="col1 fw5">
									Research Analyst
								</Typography>
							</Box>
							<Box className="al_center" pt={1} pb={4}>
								<RBtn btn_name="Try Now" onClick={handleOauthLogin} />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<ModalVideo
				channel="youtube"
				isOpen={isOpen}
				videoId="91mtDiCmlgw"
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
};
