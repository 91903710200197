import {
	Box,
	Container,
	Divider,
	Grid,
	Typography,
	Button,
	Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Check from "../../Assets/Images/check.svg";
import Check2 from "../../Assets/Images/check2.svg";

import { TypeAnimation } from "react-type-animation";
import config from "../../Utils/config";
import axios from "axios";

export const PricingSection = () => {
	const handleOauthLogin = () => {
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const url = `${OAUTH_URL}/oauth-v2/create-account-email?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=pdfquery`;
		window.location.href = url;
	};
	const [plans, setPlans] = useState([]);

	async function getAllPlans() {
		try {
			const response = await axios.get(
				`${config.PDF_API_URL}/api/v2/payment/plans/?application_id=${config.CLIENT_ID}`
			);
			setPlans(response.data);
		} catch (error) {
			setPlans(fallbaclPlanList);
		}
	}

	useEffect(() => {
		getAllPlans();
	}, []);

	const fallbaclPlanList = [
		{
			questions: "50",
			flat_price: "0",
			currency: "$",
			plan_name: "Free",
			features: [
				"Unlimited Conversations",
				"Multiple Uploads",
				"50 Questions / Month",
				"5 File Uploads / Month",
				"1 Library / Account",
				"10 Files / Library / Account",
				"File Size Limit: 10 MB",
			],
			originalPrice: "0",
			renderedPlanName: "Free",
		},
		{
			features: [
				"Unlimited Conversations",
				"Multiple Uploads",
				"20,000 Questions / Month",
				"100 File Uploads / Month",
				"10 Libraries / Account",
				"1000 Files Across All Libraries",
				"File Size Limit: 50 MB",
			],
			currency: "$",
			plan_name: "Pro",
			flat_price: "15",
			originalPrice: "15",
			renderedPlanName: "Pro",
			offer: "",
		},
	];

	return (
		<>
			<Box id="pricing" className="subsection">
				<Box pb={3}>
					<Container maxWidth="lg">
						<Box>
							<Typography variant="h2" className="col1 fw4 al_center">
								Pricing
							</Typography>
						</Box>
						<Box>
							<Typography variant="h2" className="black fw4 al_center">
								Save Hours of Effort for a Price of a Pizza
							</Typography>
						</Box>
					</Container>
				</Box>

				<Box pb={3} pt={3} className="sec_bg_2">
					<Container maxWidth="lg">
						{/* <Box pt={1}>
                            <Typography variant='h5' className='black fw5 al_center blink_text'>
                                Sign Up Now! 
                            </Typography>
                        </Box> */}
						<Box pt={2} pb={1}></Box>
					</Container>
				</Box>

				<Container maxWidth="lg">
					<Box pb={6} className="pricing-cards" pt={5}>
						<Grid
							container
							alignItems="flex-start"
							justifyContent="center"
							spacing={6}
						>
							{/* FREE */}
							{plans.map((item, index) => (
								<Grid item lg={4} sm={6} xs={10}>
									<Box className="pricingBx_wh">
										<Box className="al_center" pt={2} pb={1}>
											<Typography variant="h4" className="fw5">
												{item.plan_name}
											</Typography>
										</Box>

										<Box className="al_center" pt={2} pb={2}>
											<Typography variant="h3" className="fw5 col1">
												<sup>{item.currency}</sup>
												{item.flat_price}/month
											</Typography>
										</Box>

										<Box className="al_center" pt={5}>
											{item.features.map((item) => (
												<>
													<Box pt={"12px"} pb={"12px"}>
														<Typography
															variant="h5"
															className="fw5 black fx_fs"
															gutterBottom
														>
															<img src={Check} alt="checkbox_icon" /> &nbsp;
															&nbsp;
															<span className="al_left"> {item} </span>
														</Typography>
													</Box>
													<Divider />
												</>
											))}
										</Box>

										<Box mt={6} className="al_center pricingBtnBx">
											<Button
												variant="outlined"
												className="pricingBtn"
												onClick={handleOauthLogin}
											>
												{" "}
												GET STARTED{" "}
											</Button>
										</Box>
									</Box>
								</Grid>
							))}
							{/* PRO */}
							{/* <Grid item lg={4} sm={6} xs={10}>
								<Box className="pricingBx_rd">
									<Box className="al_center" pt={2}>
										<Typography variant="h4" className="fw5 wh">
											PRO
										</Typography>
									</Box>
									
									<Box className="al_center" pt={2} pb={2}>
										<Typography variant="h3" className="fw5 wh">
											<span>
												<sup>$</sup>15
											</span>
											/month
										</Typography>
									</Box>

									<Box className="al_center" pt={5}>
										{featureList.pro.features.map((item) => (
											<>
												<Box pt={"12px"} pb={"12px"}>
													<Typography
														variant="h5"
														className="fw5 wh fx_fs"
														gutterBottom
													>
														<img src={Check2} alt="checkbox_icon" /> &nbsp;
														&nbsp;
														<span className="al_left"> {item}</span>
													</Typography>
												</Box>

												<Divider className="wh_div" />
											</>
										))}
									</Box>

									<Box mt={6} className="al_center pricingBtnBx">
										<Button
											variant="outlined"
											className="pricingBtn2"
											onClick={handleOauthLogin}
										>
											{" "}
											UPGRADE NOW{" "}
										</Button>
									</Box>
								</Box>
							</Grid> */}
							{/* PRO PLUS */}
							<Grid item lg={4} sm={6} xs={10}>
								<Box className=" lg:hidden custom_req">
									<Box className="al_center ">
										{" "}
										<Typography variant="h4" className="">
											Custom Requirements?
										</Typography>
									</Box>
									<Box mt={6} className="al_center pricingBtnBx">
										<Button variant="outlined" className="pricingBtn">
											{" "}
											<Link
												className="hover:text-white"
												href="mailto:info@pdfquery.ai"
											>
												CONTACT US
											</Link>
										</Button>
									</Box>
								</Box>
								<Box className="hidden lg:block pricingBx_wh ">
									<Box className="al_center mt-48" pt={2} pb={1}>
										<Typography variant="h4" className="">
											Custom Requirements?
										</Typography>
									</Box>

									{/* <Box className="al_center" pt={2} pb={2}>
										<Typography variant="h3" className="fw5 col1">
											<sup>$</sup>20/month
										</Typography>
									</Box> */}

									<Box mt={6} className="al_center pricingBtnBx">
										<Button variant="outlined" className="pricingBtn">
											{" "}
											<Link
												className="hover:text-white"
												href="mailto:info@pdfquery.ai"
											>
												CONTACT US
											</Link>
										</Button>
									</Box>
								</Box>
							</Grid>

							{/* <Grid item lg={4} lg={4} sm={6} xs={10}>
                                <Box className="pricingBx_wh" mt={5}>
                                    <Box className='al_center' pt={2} pb={1}>
                                        <Typography variant='h4' className='fw5'>
                                            Enterprise
                                        </Typography>
                                    </Box>


                                    <Box className='al_center' pt={2} pb={2}>
                                        <Typography variant='h3' className='fw5 col1'>
                                            Custom Price
                                        </Typography>
                                    </Box>


                                    <Box className='al_center' pt={4}>
                                        <Box pt={1} pb={1}>
                                            <Typography variant='h5' className='fw5 black fx_fs' gutterBottom>
                                                <img src={Check} alt='checkbox_icon' /> &nbsp; &nbsp;  
                                                <span className='al_left'>Have larger, custom requirements?</span>
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box mt={2} className="al_center pricingBtnBx">
                                        <Button variant='outlined' className='pricingBtn'> CONTACT SALES </Button>
                                    </Box>


                                </Box>
                            </Grid> */}
						</Grid>
					</Box>
				</Container>
			</Box>
		</>
	);
};
